import { createContext } from "react";

export type AudioUrlContextType = {
  togglePlay: (url: string | null) => void;
  isPlaying: (url: string | null) => boolean;
};

export const AudioUrlContext = createContext<AudioUrlContextType>({
  togglePlay: () => {},
  isPlaying: () => false,
});
