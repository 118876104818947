export function generateSimpleHash() {
  // Create a random string using Date and random numbers
  const randomData = new Date().toISOString() + Math.random().toString(36).substring(2);

  // Simple hash function: Convert each character to char code and XOR it
  let hash = 0;
  for (let i = 0; i < randomData.length; i++) {
      hash = (hash << 5) - hash + randomData.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
  }

  // Convert the integer hash to a hexadecimal string
  return hash.toString(16);
}