import React, { useContext, useEffect, useState } from "react";
import DownloadItem from "../DownloadItem";
import Loading from "../Loading";
import "./index.scss";
import { t } from "i18next";
import { DownloadDetailed, Pagination } from "../../api/implementations/types";
import { UserContext } from "../../contexts/User/UserContext";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { getDownloads } from "../../api/implementations/booksApi";

const paginationsAreSame = (a: Pagination|null, b: Pagination|null) => {
  return (a === null && b === null) || (b !== null && a !== null && a.start === b.start && a.count === b.count);
}

type ComponentProps = {
  id: string;
  role: string;
  ariaLabelledby: string;
  tabindex: number;
  hidden?: boolean;
}

const showCount = 12;
const DownloadItemList: React.FC<ComponentProps> = ({ id, role, ariaLabelledby, tabindex, hidden }) => {

  const smartFetchCount = showCount + 1; const { params, downloads: items, invalidated, requestIfDifferentParams: setParams, isAwaitingResponse } = useResult( getDownloads, "downloads", paginationsAreSame,
    AuthStyle.NO_AUTH_NULLIFY,
    { start: 0, count: smartFetchCount }
  );

  const [cumulatedItems, setCumulatedItems] = useState<DownloadDetailed[] | null>(null);
  const start = params?.start || 0;

  const [nextRequestWillReturnEmpty, setNextRequestWillReturnEmpty] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!isAwaitingResponse) {
      document.getElementById(id)?.focus();
    }
  }, [isAwaitingResponse]);

  useEffect(() => {
    if (items === null) {
      if (invalidated) {
        setCumulatedItems(null);
      }
      return;
    }
    if (items.length <= showCount) {
      setNextRequestWillReturnEmpty(true);
    }
    setCumulatedItems(prevState => [
      ...(prevState !== null ? prevState : []),
      ...(items.slice(0, showCount)),
    ]);
  }, [items, invalidated]);

  return (
    <div
      className="DownloadItemList"
      id={id}
      aria-labelledby={ariaLabelledby}
      role={role}
      tabIndex={tabindex}
      hidden={hidden}
    >
      <h2 className="DownloadItemList__Title">{t("Latest Downloads")}</h2>
      {(cumulatedItems && (
        <div>
          <div className="DownloadItemList__List">
            {cumulatedItems.map(d => (
              <DownloadItem
                key={`${d.downloadID} ${d.noticeID} + ${d.downloadDate}`}
                {...d}
              />
            ))}
            {isAwaitingResponse && <Loading />}
          </div>
          {(!nextRequestWillReturnEmpty && <button
            className="DownloadItemList__More"
            onClick={() => {
              setParams({
                start: start + showCount,
                count: smartFetchCount //fetch one more to know if more
              });
            }}
          >
            {`${t("Load more")} ${t("downloads")}`}
          </button>) || (
            <p className="DownloadItemList__EOList">... {t("End of your")} {t("downloads list")}</p>
          )}
        </div>
      )) || (user && (
        <Loading />
      )) || <p className="DownloadItemList__LoggedOutMessage">{`${t("Your downloads")} ${t("will show when you log in.")}`}</p>}
    </div>
  );
};

export default DownloadItemList;