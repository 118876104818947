import { useEffect, useContext, useState, useMemo } from "react";
import { AddRemove, ErrorContext, ErrorSource } from "../contexts/Error/ErrorContext";
import useApi from "../api/hooks/useApi";
import { getBooks } from "../api/implementations/booksApi";
import { anyOf } from "../utils/references";
import BooksContext from "../contexts/Books/BooksContext";
import SearchContext from "../contexts/Search/SearchContext";
import { valuesParamAreDifferent } from "../utils/search";
import { UserContext } from "../contexts/User/UserContext";
import { bookDownloadedBeforeConnected, shouldInvalidateDownloadLink, unsetDownloadLinks } from "../utils/authedBooks";
import { ErrorName } from "../api/implementations/types";
import { getErrorResponse } from "../utils/errorHandler";
import { AxiosError } from "axios";

const idx = ErrorSource.BookGrid;

function useBookSearch() {
  const {
    addOrRemoveSourcedError,
    removeFirstFromSource,
    sourcedErrors
  } = useContext(ErrorContext);
  const errorsFromHere = useMemo(() => (
    typeof sourcedErrors[idx] !== "undefined" ? sourcedErrors[idx] : []
  ), [sourcedErrors]);
  const { user, userSetAt, transactionInProgress } = useContext(UserContext);
  const {
    books,
    setBooks,
    totalCount,
    setTotalCount,
    receivedCount,
    setReceivedCount,
  } = useContext(BooksContext);
  const valuesParam = useContext(SearchContext);
  const { page, count, queryType, queryText, gatewayGenreCode, format, youth } = valuesParam;
  const [currentBooksValuesParam, setCurrentBooksValuesParam] = useState({
    page,
    count,
    queryType,
    queryText,
    gatewayGenreCode,
    format,
    youth,
  });

  const [recoveryAttemptTargetPage, setRecoveryAttemptTargetPage] = useState<number>(-1);
  const [recoveryAttemptCurrentPage, setRecoveryAttemptCurrentPage] = useState<number>(-1);
  const [dataSetAt, setDataSetAt] = useState<number>(0);

  const {
    exec: fetchBooks,
    data,
    error,
    isIdle,
    isError,
    isSuccess,
    isPending,
    clearApi,
  } = useApi(getBooks);

  useEffect(() => {
    if (isPending) return;
    if (shouldInvalidateDownloadLink(user, (books && books[0]) || null)) {
      books && setBooks(books.map(unsetDownloadLinks));
      setDataSetAt(new Date().getTime());
    } else if (user && bookDownloadedBeforeConnected(user, (books && books[0]) || null, userSetAt?.getTime() || 0, dataSetAt)) {
      setBooks(null);
      setDataSetAt((new Date()).getTime());
      setTotalCount(0);
      setReceivedCount(0);
      // forces refetch with session id
    }
  }, [user, setBooks, books, isPending]);

  useEffect(() => {
    const valuesParamsWithRecovery = recoveryAttemptTargetPage === -1
      ? valuesParam
      : {
        ...valuesParam,
        page: recoveryAttemptCurrentPage,
      };
    const exitEarly = anyOf({
      transactionInProgress,
      booksWereSetAndQueryParamsHaventChanged: books
        && !valuesParamAreDifferent(currentBooksValuesParam, valuesParamsWithRecovery),
      NOT_isIdle: !isIdle,
      errorCreatedByMe: errorsFromHere.length,
    });

    if (exitEarly.bool) {
      return;
    }

    fetchBooks(valuesParamsWithRecovery);
  }, [
    books,
    fetchBooks,
    isIdle,
    errorsFromHere,
    page,
    count,
    queryType,
    queryText,
    gatewayGenreCode,
    format,
    youth,
    transactionInProgress,
    recoveryAttemptCurrentPage
  ]);

  useEffect(() => {
    if (isError && error) {
      const serverError = getErrorResponse(error as AxiosError);
      if (serverError?.name === ErrorName.REQUEST_AHEAD_OF_CACHE) {
        setRecoveryAttemptTargetPage(page);
        setRecoveryAttemptCurrentPage(0);
        clearApi();
        return;
      }
    }
    if (!isError) {
      removeFirstFromSource(idx);
    } else if (error && errorsFromHere.findIndex((err) => err.error === error) === -1) {
      addOrRemoveSourcedError(idx, {
        error,
        date: new Date(),
      }, AddRemove.add);
    }
  }, [error, isError, addOrRemoveSourcedError]);

  useEffect(() => {
    if (!isSuccess) return;
    if (data !== null) {
      if (recoveryAttemptTargetPage !== -1) {
        if (recoveryAttemptCurrentPage < recoveryAttemptTargetPage) {
          setRecoveryAttemptCurrentPage(recoveryAttemptCurrentPage + 1);
          clearApi();
          return;
        } else if (recoveryAttemptTargetPage === recoveryAttemptCurrentPage) {
          setRecoveryAttemptCurrentPage(-1);
          setRecoveryAttemptTargetPage(-1);
        } else {
          throw new Error("Gone too far");
        }
      }
      setBooks(data.books);
      setDataSetAt((new Date()).getTime())
      setTotalCount(data.totalCount);
      setReceivedCount(data.receivedCount);
      setCurrentBooksValuesParam({
        page,
        count,
        queryType,
        queryText,
        gatewayGenreCode,
        format,
        youth
      });
      clearApi();
    } else {
      addOrRemoveSourcedError(idx, {
        error: new Error("Unable to fetch Books, but request has succeeded."),
        date: new Date(),
      }, AddRemove.add);
    }
  }, [isSuccess, data, addOrRemoveSourcedError]);

  return {
    page,
    books,
    totalCount,
    receivedCount,
    isPending,
    clearApi,
    attemptRecoveryFromError: () => {
      removeFirstFromSource(idx);
      clearApi();
    },
    currentBooksValuesParam,
  };
}

export default useBookSearch;
