import { BookReducedInfo } from "../../types/book";

export enum ErrorName {
  "REQUEST_AHEAD_OF_CACHE" = "REQUEST_AHEAD_OF_CACHE",
  "MALFORMED_REMOTE_RESPONSE" = "MALFORMED_REMOTE_RESPONSE",
}

export type ApiErrorResponse = {
  result: undefined;
  error: {
    code: number;
    reason: string;
    name: ErrorName;
  },
  extra: string;
}

export type ApiResultResponse<T> = {
  result: T
  error: undefined;
  extra: undefined;
}

export type ApiResponse<T> =
  ApiResultResponse<T>
  | ApiErrorResponse;

export enum RemoteServiceId {
  BBR="BBR",
  BSR="BSR",
  ES="ES",
  UNI="UNI",
}

export enum FromInterface {
  Biblio="Biblio",
  BBR="BBR",
}

export type Pagination = { start: number; count: number; };

export type WishResult = BookReducedInfo & {
  noticeID: string;
  wishDate: string;
};

export type DownloadDetailed = {
  downloadID: string;
  login: string;
  lastName: string;
  firstName: string;
  group: string;
  userAccountID: string;
  userServiceId: string;
  downloadDate: string;
  noticeID: string;
  title: string;
  author: string;
  noticeNr: string;
  fromInterface: string;
  noticeServiceId: string;
};
