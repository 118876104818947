import React from "react";
import { t } from "i18next";

type ComponentProps = {
  children?: React.ReactNode;
}

const PrivacyPolicyLink: React.FC<ComponentProps> = ({ children }) => {
  return (
    <a className="PrivacyPolicy__Link" target="__blank" href={`https://meow.ch/fr/privacy`}>
      {children || t("Privacy Policy")}
    </a>
  );
 };

export default PrivacyPolicyLink;