import { useState, useEffect, useContext, useCallback } from "react";
import { UserContext } from "../contexts/User/UserContext";
import { AddRemove, ErrorContext, ErrorSource } from "../contexts/Error/ErrorContext";
import authenticate from "../api/implementations/userApi";
import useApi from "../api/hooks/useApi";
import { anyOf } from "../utils/references";

const idx = ErrorSource.Login;

const useAuth = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [prevent, setPrevent] = useState<boolean>(false);
  const [isTriggerSubmitWhenAvailable, setIsTriggerSubmitWhenAvailable] = useState(true);
  const {
    user,
    setUser: setStorageUser,
    setTransactionInProgress,
    transactionInProgress
  } = useContext(UserContext);
  const {
    addOrRemoveSourcedError,
    removeFirstFromSource,
    sourcedErrors
  } = useContext(ErrorContext);
  const errorsFromHere = typeof sourcedErrors[idx] !== "undefined" ? sourcedErrors[idx] : [];

  const {
    exec: fetchUser,
    data,
    error,
    isIdle,
    isError,
    isSuccess,
    isPending,
    clearApi,
  } = useApi(authenticate);

  useEffect(() => {
    const exitEarly = anyOf({
      NOT_TriggerSubmitWhenAvailable: !isTriggerSubmitWhenAvailable,
      user,
      NOT_Username: !username,
      NOT_Password: !password,
      NOT_isIdle: !isIdle,
      prevented: prevent,
      transactionInProgress,
      errorCreatedByMe: errorsFromHere.length,
    });

    if (exitEarly.bool) {
      return;
    }

    setTransactionInProgress(true);
    fetchUser({ username, password });
  }, [
    user,
    username,
    password,
    prevent,
    isPending,
    fetchUser,
    isTriggerSubmitWhenAvailable,
    transactionInProgress,
    setTransactionInProgress,
    isIdle,
    errorsFromHere,
  ]);

  useEffect(() => {
    if (!isError && typeof sourcedErrors[idx] !== "undefined") {
      removeFirstFromSource(idx);
    } else if (error && errorsFromHere.findIndex((err) => err.error === error) === -1) {
      addOrRemoveSourcedError(idx, {
        error,
        date: new Date(),
      }, AddRemove.add);
      setTransactionInProgress(false);
      setIsTriggerSubmitWhenAvailable(false);
    }
  }, [error, isError, addOrRemoveSourcedError, setTransactionInProgress, clearApi]);

  useEffect(() => {
    if (!isSuccess) return;
    setStorageUser(data && data.user);
    clearApi();
    setIsTriggerSubmitWhenAvailable(false);
  }, [isSuccess, data, setStorageUser, clearApi]);

  const triggerSubmitWhenAvailable = useCallback(() => {
    setIsTriggerSubmitWhenAvailable(true);
  }, [setIsTriggerSubmitWhenAvailable]);

  const triggerSubmit = useCallback(() => {
    setPrevent(false);
    triggerSubmitWhenAvailable();
  }, [setIsTriggerSubmitWhenAvailable, setPrevent]);

  return {
    user,
    triggerSubmitWhenAvailable,
    triggerSubmit,
    isAwaitingResponse: isPending,
    isTransactionInProgress: transactionInProgress,
    setUsername,
    setPassword,
    disconnect: () => {
      setStorageUser(null);
      setUsername("");
      setPassword("");
    },
    username,
    password,
    setPrevent,
    prevent,
    attemptRecoveryFromError: () => {
      removeFirstFromSource(idx);
      clearApi();
    },
  };
};

export default useAuth;
