import React, { useCallback, useEffect, useState } from "react";
import BookCard from "../BookCard";
import { BookReducedInfo } from "../../types/book";
import Loading from "../Loading";
import './index.scss';
import { RemoteServiceId } from "../../api/implementations/types";
import { t } from "i18next";
import { NavigationKeys } from "../../types";

type ComponentProps = {
  title: string;
  books: BookReducedInfo[] | null;
  isPending: boolean;
  noBooksMesssageOrEl: string | JSX.Element;
  modifier?: "wishlist";
  onWishChange?: (prop: { nextWished: boolean; code: string; origin: RemoteServiceId; }) => void;
  onIsListItemFocusedChange: (focused: boolean) => void;
} & { children?: React.ReactNode; };

export default function Books({
  title,
  books,
  isPending,
  noBooksMesssageOrEl,
  children,
  modifier,
  onWishChange,
  onIsListItemFocusedChange,
}: ComponentProps): JSX.Element {
  const [focusedBookIndex, setFocusedBookIndex] = useState<number | null>(null);
  const [activatedBookIndex, setActivatedBookIndex] = useState<number | null>(null);

  useEffect(() => {
    if (!isPending) {
      const gridListElement = document.getElementById(`book-grid-list`);
      if (gridListElement && document.activeElement !== gridListElement) {
        gridListElement?.focus();
        onIsListItemFocusedChange(false);
        return;
      }
    }
  }, [isPending]);

  useEffect(() => {
    setFocusedBookIndex(null);
    setActivatedBookIndex(null);
  }, [books]);

  useEffect(() => {
    const gridListElement = document.getElementById(`book-grid-list`);
    if (focusedBookIndex === null) {
      if (gridListElement && document.activeElement !== gridListElement) {
        gridListElement?.focus();
        onIsListItemFocusedChange(false);
        return;
      }
    } else if (activatedBookIndex === null) {
      const el = document.getElementById(`book-${focusedBookIndex}`);
      if (el && document.activeElement !== el) {
        el?.focus();
        onIsListItemFocusedChange(true);
        return;
      }
    }
  }, [focusedBookIndex, activatedBookIndex]);

  const moveBetweenItems = useCallback((event: React.KeyboardEvent) => {
    if (!books || activatedBookIndex !== null) return;
    switch (event.key) {
      case 'ArrowRight':
        event.preventDefault();
        setFocusedBookIndex((prevIndex) => {
          const nextIndex = (prevIndex !== null ? Math.min(prevIndex + 1, books.length - 1) : 0);
          return nextIndex;
        });
        break;
      case 'ArrowLeft':
        event.preventDefault();
        setFocusedBookIndex((prevIndex) => {
          const nextIndex = (prevIndex !== null ? Math.max(prevIndex - 1, 0) : 0);
          return nextIndex;
        });
        break;
      case 'Home':
        event.preventDefault();
        setFocusedBookIndex(0);
        break;
      case 'End':
        event.preventDefault();
        setFocusedBookIndex(books.length - 1);
        break;
      default:
        break;
    }
  }, [books, activatedBookIndex]);

  return (
    <section
      className="BookGrid__Container"
      aria-labelledby="book-list-heading"
    >
      <h2
        className="BookGrid__Title"
        id="book-list-heading"
        aria-live="assertive"
      >
        {title}
      </h2>
      {((books === null && isPending) && (
        <Loading />
      )) || ((books === null && (
        <p className="BookGrid__NoResults" aria-live="polite">
          {t("Oops... Please try reloading the page")}
        </p>
      ))) || ((books!.length <= 0 && !isPending && (
        <p className="BookGrid__NoResults" aria-live="polite">
          {noBooksMesssageOrEl}
        </p>
      )) || (
        <>
          <ul
            id={`book-grid-list`}
            className={`BookGrid__List ${modifier ? ` BookGrid__List--${modifier}` : ''}`}
            role="list"
            aria-label={t("Book results")}
            aria-activedescendant={focusedBookIndex !== null ? `book-${focusedBookIndex}` : undefined}
            tabIndex={0}
            onKeyDown={e => moveBetweenItems(e)}
            onFocus={() => {
              onIsListItemFocusedChange(true);
            }}
            onBlur={() => {
              onIsListItemFocusedChange(false);
            }}
          >
            {books!.map((book, index) => {
              return (
                <li
                  id={`book-${index}`}
                  key={`${book.code}${book.origin}`}
                  role="listitem"
                >
                  <BookCard
                    bookListIndex={index}
                    cardActive={activatedBookIndex === index}
                    book={book}
                    giveBackFocus={() => setActivatedBookIndex(null)}
                    size={modifier ? "miniature" : undefined}
                    onWishChange={onWishChange ? onWishChange : undefined}
                  />
                </li>
              );
            })}
          </ul>
          {children}
        </>
      ))}
    </section>
  );
}
