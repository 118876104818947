import { useEffect, useContext, useMemo } from "react";
import { AddRemove, ErrorContext, ErrorSource } from "../contexts/Error/ErrorContext";
import useApi from "../api/hooks/useApi";
import { anyOf } from "../utils/references";
import GenresContext from "../contexts/Genres/GenresContext";
import { getGenres } from "../api/implementations/genresApi";

const idx = ErrorSource.Genres;

function useGenres() {
  const { genres, setGenres } = useContext(GenresContext);
  const {
    addOrRemoveSourcedError,
    removeFirstFromSource,
    sourcedErrors
  } = useContext(ErrorContext);

  const errorsFromHere = useMemo(() => (
    typeof sourcedErrors[idx] !== "undefined" ? sourcedErrors[idx] : []
  ), [sourcedErrors]);

  const {
    exec: initGenres,
    data,
    error,
    isIdle,
    isError,
    isSuccess,
    isPending,
    clearApi,
  } = useApi(getGenres);

  useEffect(() => {
    const exitEarly = anyOf({
      genresLength: genres.length > 0,
      NOT_isIdle: !isIdle,
      errorCreatedByMe: errorsFromHere.length,
    });

    if (exitEarly.bool) {
      return;
    }

    initGenres(undefined);
  }, [
    genres,
    initGenres,
    isIdle,
    errorsFromHere,
  ]);

  useEffect(() => {
    if (!isError) {
      removeFirstFromSource(idx);
    } else if (error && errorsFromHere.findIndex((err) => err.error === error) === -1) {
      addOrRemoveSourcedError(idx, {
        error,
        date: new Date(),
      }, AddRemove.add);
    }
  }, [error, isError, addOrRemoveSourcedError, sourcedErrors]);

  useEffect(() => {
    if (!isSuccess) return;
    if (data !== null && genres.length <= 0) {
      setGenres(data);
    } else {
      addOrRemoveSourcedError(idx, {
        error: new Error("Unable to fetch Genres, but request has succeeded."),
        date: new Date(),
      }, AddRemove.add);
    }
  }, [isSuccess, data, addOrRemoveSourcedError]);

  useEffect(() => {
    if (genres.length > 0 && isSuccess) {
      clearApi();
    }
  }, [genres, isSuccess, clearApi]);

  return {
    genres,
    isPending,
    clearApi,
    attemptRecoveryFromError: () => {
      removeFirstFromSource(idx);
      clearApi();
    },
  };
}

export default useGenres;
