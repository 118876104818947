import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "./index.scss";

export default function Loading(): JSX.Element {
  return (
    <div className="Loading__Container">
      <PulseLoader color={import.meta.env.VITE_MIDDLE_BG_COLOR} />
    </div>
  );
}
