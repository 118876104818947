import { User } from "../types/user";
import { isUser } from "../contexts/User/UserProvider";
import { Book, BookDownloadFiles, BookFiles, ZipKeys } from "../types/book";
import { anyOf, firstOf } from "./references";

export function getBookDlUri(book?: { files: BookDownloadFiles }) {
  return book?.files?.zip?.uri || book?.files?.zip_brf?.uri;
}

export const isBookDownloadable = (book: { files: Partial<BookDownloadFiles>; }): book is { files: BookDownloadFiles; } => Boolean(
  (["zip", "zip_brf"] as ZipKeys[]).reduce((p, c) => (
    p || (typeof book.files[c] !== "undefined" && (book.files[c]?.uri.indexOf("dlfrom=http") !== -1))
  ), false)
);

export const unsetDownloadLinks = (book: Book): Book => {
  const emptyZip = { uri: "", size: 0 };
  const files = {
    ...book.files,
    ...(
      book.files.zip !== undefined
        ? { zip: emptyZip,  }
        : { zip_brf: emptyZip }
    )
  };
  return {
    ...book,
    files,
  };
};

export const showingConnectedButBrowserNotSendingSessID = (
  user: User | null,
  book: Book | null,
  userSetAt: number,
  bookSetAt: number
) => {
  return (
  book && isUser(user) && !isBookDownloadable(book) && userSetAt < bookSetAt
)};

export const bookDownloadedBeforeConnected = (
  user: User | null,
  book: Book | null,
  userSetAt: number,
  bookSetAt: number
) => (
  book && isUser(user) && !isBookDownloadable(book) && userSetAt > bookSetAt
);

export const shouldInvalidateDownloadLink = (user: User | null, book: Book | null): book is Book => (
  book !== null && !isUser(user) && isBookDownloadable(book)
);

export const needsToFetchBook = (
  isTransactionInProgress: boolean,
  isAwaitingBookResponse: boolean,
  user: User | null,
  book: Book | null,
  userSetAt: number,
  bookSetAt: number,
) => {
  return !firstOf(true, {
      isTransactionInProgress,
      isAwaitingBookResponse
    }).bool
    && anyOf({
      bookNotSet: !book,
      isShowingConnectedButBrowserNotSendingSessID: showingConnectedButBrowserNotSendingSessID(user, book, userSetAt, bookSetAt),
      isBookDownloadedBeforeConnected: bookDownloadedBeforeConnected(user, book, userSetAt, bookSetAt),
    }).bool;
};
