import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorContext, ErrorToAction } from "src/contexts/Error/ErrorContext";
import "./index.scss";

function ErrorModal() {
  const { shownErrorStack, dismissFirstFromSource } = useContext(ErrorContext);
  const { t } = useTranslation(["common"]);
  const [dismissedError, setDismissedError] = useState<ErrorToAction | null>(null);
  const [shownError] = shownErrorStack;

  useEffect(() => {
    if (dismissedError === null) {
      return;
    }
    if (dismissedError.dismissAction) {
      dismissedError.dismissAction();
    }
    setDismissedError(null);
  }, [dismissedError]);

  const handleDismiss = useCallback(() => {
    setDismissedError(shownError);
    dismissFirstFromSource(shownError.source);
  }, [shownError]);

  return (shownError && (
    <div className="ErrorModal__Overlay">
      <div className="ErrorModal__Container">
        <header className="ErrorModal__Header">
          <button className="ErrorModal__CloseButton" onClick={handleDismiss}>
            ×
          </button>
        </header>
        <p className="ErrorModal__Message">{shownError.message}</p>
        <button className="ErrorModal__ActionButton" onClick={handleDismiss}>
          {t("common:Dismiss")}
        </button>
      </div>
    </div>
  )) || null;
}

export default ErrorModal;
