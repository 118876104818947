import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Book } from "../../types/book";
import Loading from "../Loading";
import BookInfoField from "./BookInfoField";
import { UserContext } from "../../contexts/User/UserContext";
import BookCover from "../BookCover";
import { I18NLabelHandle } from "../../types/search";
import { forcedOrigin } from "../../utils/search";
import { ucfirst } from "../../utils/string";
import "./index.scss";
import CornerFormatPlayer from "../CornerFormatPlayer";
import WishButton from "../WishButton";
import { RemoteServiceId } from "../../api/implementations/types";
import DownloadButton from "../DownloadButton";
import { removeCD, removeLocationComma } from "../../utils/bbr";

const isBBROnly = import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN && import.meta.env.VITE_ORIGIN === RemoteServiceId.BBR;

const getReaderOnly = (reader?: string) => {
  if (!reader) {
    return undefined;
  }
  const s = reader?.split("Lu par: ");
  return s[s.length - 1];
};

function getBookDlUri(book?: Book) {
  return book?.files?.zip?.uri || book?.files?.zip_brf?.uri;
}

export default function BookInfo({ book }: { book: Book }): JSX.Element {
  const { t } = useTranslation(["common"]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const isLoggedIn = user !== null;
  const [expandImageOnMobile, setExpandImageOnMobile] = useState(false);

  useEffect(() => {
    if (book !== undefined) {
      setLoading(false);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [book]);

  const tags = useMemo(() => book?.motsMatieres?.filter(s => /^[A-Z:.(),-]+$/.test(s)).map(ucfirst).join(', '), [book]);

  return (
    <div className="BookInfo__Container" role="region" aria-labelledby="book-info-title">
      {loading === true ? <Loading /> : null}
      <CornerFormatPlayer
        book={book}
        size="full"
        tabIndex={0}
      >
        <WishButton
          size={'full'}
          code={book.code}
          title={book.title}
          wished={book?.wished || false}
          tabIndex={0}
          origin={book.origin}
        />
        <DownloadButton
          size={'full'}
          aria-label={`${t("Download")} "${book.title}"`}
          book={book}
          tabIndex={0}
          aria-hidden="false"
        />
        <div
          aria-hidden="true"
          tabIndex={-1}
          className={
            `BookInfo__CoverContainer${
              expandImageOnMobile
                ? " BookInfo__CoverContainer--expanded"
                : ""
            }`
          }
          onClick={() => setExpandImageOnMobile(!expandImageOnMobile)}
        >
          <BookCover cover={book.cover} byWidth={false} />
        </div>
      </CornerFormatPlayer>
      <div className="BookInfo__InfoContainer">
        <h2
          id="book-info-title"
          className="BookInfo__Title"
          aria-label={`${t("Détails du livre")}: "${book.title}"`}
        >
          {book?.title}
        </h2>
        <BookInfoField
          labelI18NHandle={I18NLabelHandle.Author}
          value={book?.author}
          underlined
        />
        <BookInfoField
          labelI18NHandle={I18NLabelHandle.Reader}
          value={forcedOrigin === RemoteServiceId.ES ? getReaderOnly(book?.reader) : book?.reader}
          underlined
        />
        <BookInfoField
          labelI18NHandle={I18NLabelHandle.Duration}
          value={removeCD(book.media)}
        />
        {((isBBROnly && book?.editor && book?.year && (
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Editor}
            value={`${removeLocationComma(book.editor)}, ${book.year}`}
          />
        )) || null)}
        <BookInfoField
          labelI18NHandle={I18NLabelHandle.ISBN}
          value={book?.isbn}
        />
        <BookInfoField
          labelI18NHandle={isBBROnly ? I18NLabelHandle.BookNr : I18NLabelHandle.NoticeNr}
          value={book?.code}
        />
        <BookInfoField
          labelI18NHandle={I18NLabelHandle.Genre}
          value={book?.genre}
          searchValue={book?.gatewayGenreCode}
          underlined
        />
        {(tags && (
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Tags}
            value={tags.split(',').slice(0, 4)}
            underlined
          />
        )) || null}
        {((!isBBROnly && (
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.PublishedOn}
            value={book?.date}
          />
        )) || null)}
        {(forcedOrigin !== book.origin && (
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Origin}
            value={book.origin}
            underlined
          />
        )) || null}
        {((!isBBROnly && (
          <BookInfoField
            labelI18NHandle={I18NLabelHandle.Youth}
            value={(book.jeunesse ? t("Yes") : t("No")) || ""}
          />
        )) || null)}
        <a
          className={`BookInfo__DownloadButton${isLoggedIn ? "" : " BookInfo__DownloadButton--disabled"}`}
          href={isLoggedIn ? getBookDlUri(book) : "#"}
          onClick={(e) => {
            if (!isLoggedIn) e.preventDefault();
          }}
          aria-disabled={!isLoggedIn}
        >
          {user !== null ? t("common:Download") : t("common:Connect")}
        </a>
      </div>
    </div>
  );
}