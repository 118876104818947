import React, { useContext, useMemo } from "react";
import { BiBraille, BiPause } from "react-icons/bi";
import { BookReducedInfo, MediaType } from "../../types/book";
import { AudioUrlContext } from "../../contexts/Audio/AudioUrlContext";
import { MdOutlineHeadset } from "react-icons/md";
import "./index.scss";
import { t } from "i18next";

function CornerFormatPlayer({ children, book, size, ...rest }: { children: React.ReactNode, book: BookReducedInfo, size: "card" | "full" | "miniature" } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const audioUrl: string | null = useMemo(() => (
    book?.files?.samples && book?.files?.samples[0]
      ? book.files.samples[0]
      : null
  ), [book?.files?.samples]);
  const { togglePlay, isPlaying } = useContext(AudioUrlContext);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      togglePlay(audioUrl);
    }
  };

  const isBraille = book.mediaType === MediaType.BIN;
  const isAudioAvailable = audioUrl !== null && !isBraille;

  return (
    <div className={`CornerFormatPlayer__KeepRelativeToCover CornerFormatPlayer__KeepRelativeToCover--${size}`}>
      <div
        className={`HeadsetContainer${
          !isAudioAvailable
            ? (isBraille
              ? " HeadsetContainer--braille"
              : " HeadsetContainer--noAudio")
            : ""
        } ActionButton`}
        onClick={isAudioAvailable ? () => togglePlay(audioUrl) : undefined}
        onKeyDown={isAudioAvailable ? handleKeyDown : undefined}
        role={isAudioAvailable ? "button" : undefined}
        tabIndex={0}
        aria-label={
          isAudioAvailable
          ? t("Play audio sample")
          : isBraille
            ? t("Book in braille format (BRF local or US)")
            : t("Audio sample unavailable")
        }
        {...rest}
      >
        {isPlaying(audioUrl) === true ? (
          <BiPause size="25" aria-hidden="true" tabIndex={-1} />
        ) : (
          book.mediaType === MediaType.BIN
            ? <BiBraille aria-hidden="true" tabIndex={-1} />
            : <MdOutlineHeadset size="25" aria-hidden="true" tabIndex={-1} />
        )}
      </div>
      {children}
    </div>
  );
}

export default CornerFormatPlayer;