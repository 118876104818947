import React, { useCallback, useMemo } from "react";
import { TimedError, ErrorSource } from "../../../contexts/Error/ErrorContext";
import useErrorManager from "../../../hooks/useErrorManager";
import errorDsiplayHandleGen from "../../../utils/errorHandler";
import "./index.scss";

type GenreSelectProps<T> = {
  value?: string;
  options: T[];
  extractor: (opt: T) => [string, string];
  attemptRecovery?: () => void;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
};

export default function Selector<T>({
  value,
  options,
  extractor,
  onChange,
  attemptRecovery,
}: GenreSelectProps<T>): JSX.Element {
  const opts = useMemo(() => options.map(extractor), [options]);
  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => (
    errorDsiplayHandleGen({ attemptRecoveryFromError: attemptRecovery || (() => undefined) })(props)
  ), [attemptRecovery]);

  useErrorManager(ErrorSource.Genres, makeErrorToAction);

  return (
    <div className="Selector__Container">
      <select className="Selector__Select"
        onChange={onChange}
        value={value}
      >
        {opts.map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
