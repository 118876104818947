import React, { MouseEventHandler } from "react";
import { Book } from "../../types/book";
import getBookCover from "../../utils/cover";
import "./index.scss";

type BookCoverProps = {
  cover: Book["cover"];
  byWidth?: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export default function BookCover({ cover, byWidth = true, ...rest }: BookCoverProps) {
  return (
    <img
      className={`BookCoverImage BookCoverImage--${byWidth !== false ? "byWidth" : "byHeight"}`}
      src={getBookCover(cover)}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = getBookCover(cover);
      }}
      {...rest}
    />
  );
}