import React from "react";
import { Book } from "../../types/book";
import "./index.scss";

export default function BookSynopsis({ book }: { book: Book }): JSX.Element {
  return (
    <div className="BookSynopsis__Container">
      <p className="BookSynopsis__Synopsis">{book?.summary}</p>
    </div>
  );
}