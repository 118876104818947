const map = new WeakMap<object | Function, number>();
const nonObjectMap = new Map<string, { value: any; id: number }>();
const nameMap = new Map<string, number[]>();
let id = 0;

type FunctionForNameTrick<T> = () => T;

const nameOf = <T>(f: FunctionForNameTrick<T>) => (f).toString().replace(/[ |\\(\\)=>]/g, "");

type ReferenceIds = { variableName: string; ids: number[]; };

export default function getReferenceId<T>(
  funcWrappedVariable: FunctionForNameTrick<T>
): ReferenceIds {
  const variableName = nameOf(funcWrappedVariable);
  const b = funcWrappedVariable();
  let currentId: number;

  if ((typeof b === "object" && b !== null) || typeof b === "function") {
    if (!map.has(b)) {
      map.set(b, id);
      id += 1;
    }
    currentId = map.get(b) as number;
  } else {
    if (!nonObjectMap.has(variableName) || nonObjectMap.get(variableName)!.value !== b) {
      nonObjectMap.set(variableName, { value: b, id });
      id += 1;
    }
    currentId = nonObjectMap.get(variableName)!.id;
  }

  if (!nameMap.has(variableName)) {
    nameMap.set(variableName, []);
  }

  const ids = nameMap.get(variableName) as number[];
  if (!ids.includes(currentId)) {
    ids.push(currentId);
  }

  return { variableName, ids };
}

export type ShouldExitWithCulprit = {
  bool: boolean;
  culprit: string;
};

export function anyOf(o: { [k: string]: any; }): ShouldExitWithCulprit {
  return Object.entries(o)
    .reduce(
      ({ bool, culprit }: ShouldExitWithCulprit, [k, v]) => ({
        bool: bool || Boolean(v),
        culprit: v
          ? `${k}${(culprit.length > 0 && `,${culprit}`) || ""}`
          : culprit
      }),
      { bool: false, culprit: "" }
    );
}

export type ShouldExitWithCulpritFirst = {
  bool: boolean;
  culprit: string;
};

/**
 *
 * @param bool boolean, match values to
 * @param o key value
 * @returns { bool: boolean; culprit: string; } the first property name whose value loosely matches bool
 */
export function firstOf(bool: boolean, o: { [k: string]: any; }): ShouldExitWithCulpritFirst {
  const idx = Object.values(o).findIndex(v => Boolean(v) === bool);
  return (idx !== -1) ? {
    bool: bool,
    culprit: Object.keys(o)[idx],
  }
  : { bool: !bool, culprit: "" }
}

type DictNum = {
  [k: string]: number;
};

const mutableIdLog: DictNum = {};

export function idLog(
  {
    id: identifier,
    increase
  }: { id: string, increase?: boolean; },
  ...args: any[]
) {
  if (import.meta.env.VITE_NODE_ENV !== "development") {
    return [];
  }
  const { [identifier]: value } = mutableIdLog;

  if (increase) {
    mutableIdLog[identifier] = value === undefined
      ? 0
      : mutableIdLog[identifier] + 1;
  } else if (value === undefined && typeof increase === "undefined") {
    return [identifier, ...args];
  }

  return [identifier, mutableIdLog[identifier], ...args];
}
