import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { t } from "i18next";
import { Pagination, RemoteServiceId, WishResult } from "../../api/implementations/types";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { getWishes } from "../../api/implementations/booksApi";
import Books from "../Books";
import { UserContext } from "../../contexts/User/UserContext";
import Loading from "../Loading";

const paginationsAreSame = (a: Pagination|null, b: Pagination|null) => {
  return (a === null && b === null) || (b !== null && a !== null && a.start === b.start && a.count === b.count);
}

const filterUnwished = (wishes: WishResult[], filterOutCodes: CodeOriginId[]) => {
return wishes.filter(wish => -1 === filterOutCodes.findIndex(({ code, origin }) => wish.code === code && wish.origin === origin ))
}

type CodeOriginId = { code: string; origin: RemoteServiceId; };

const showCount = 12;

type ComponentProps = {
  id: string;
  role: string;
  ariaLabelledby: string;
  tabindex: number;
  hidden?: boolean;
}

const WishItemList: React.FC<ComponentProps> = ({ id, role, ariaLabelledby, tabindex, hidden }) => {
  const smartFetchCount = showCount + 1;
  const { params, wishes: items, invalidated, requestIfDifferentParams: setParams, isAwaitingResponse } = useResult(
    getWishes,
    "wishes",
    paginationsAreSame,
    AuthStyle.NO_AUTH_NULLIFY,
    { start: 0, count: smartFetchCount }
  );
  const [filterOutCodes, setFilterOutCodes] = useState<CodeOriginId[]>([]);
  const [cumulatedItems, setCumulatedItems] = useState<WishResult[] | null>(null);
  const start = params?.start || 0;

  const [nextRequestWillReturnEmpty, setNextRequestWillReturnEmpty] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (items === null) {
      if (invalidated) {
        setCumulatedItems(null);
      }
      return;
    }
    if (items.length <= showCount) {
      setNextRequestWillReturnEmpty(true);
    }
    setCumulatedItems(prevState => filterUnwished([
      ...(prevState !== null ? prevState : []),
      ...(items.slice(0, showCount)),
    ], filterOutCodes));
  }, [items, invalidated]);

  const filtered = useMemo(() => ((cumulatedItems && filterUnwished(cumulatedItems, filterOutCodes)) || []), [cumulatedItems, filterOutCodes]);

  return (
    <div
      className="WishItemList"
      id={id}
      aria-labelledby={ariaLabelledby}
      role={role}
      tabIndex={tabindex}
      hidden={hidden}
    >
      {(cumulatedItems && (
        <Books
          title={t("Wishes")}
          modifier="wishlist"
          noBooksMesssageOrEl={
            <>
              {`${t("No wishes yet")}. ${t("Whenever you add books to your wishlist, they will show up here.")}`}
            </>
          }
          books={filtered.map(w => ({ ...w, wished: true, }))}
          isPending={isAwaitingResponse}
          onWishChange={({ nextWished, ...identifier }) => {
            !nextWished && setFilterOutCodes(prevState => [...prevState, identifier]);
          }}
          onIsListItemFocusedChange={() => undefined}
        >
          {((!nextRequestWillReturnEmpty && (
            <button
              className="WishItemList__More"
              onClick={() => {
                setParams({
                  start: start + showCount,
                  count: smartFetchCount //fetch one more to know if more
                });
              }}
            >
              {`${t("Load more")} ${t("wishes")}`}
            </button>
          ))) || (
            <p className="WishItemList__EOList">... {t("End of your")} {t("wishlist")}</p>
          )}
        </Books>
      )) || (user && (
        <Loading />
      )) || <p className="WishItemList__LoggedOutMessage">{`${t("Your wishes")} ${t("will show when you log in.")}`}</p>}
    </div>
  );
};

export default WishItemList;