import React, { useContext } from 'react';
import './index.scss';
import { t } from 'i18next';
import { UserContext } from '../../contexts/User/UserContext';
import { getJwt, getJwtExpirationDate } from '../../utils/jwt';

interface User {
  addressId?: string;
  displayName?: string;
  firstName?: string;
  id?: string;
  login?: string;
  freeOne?: string;
  lastName?: string;
  mail?: string;
}

const UserInfo: React.FC = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="UserInfo">
      <h1 className="UserAccount__Title">{t("User Account")}</h1>
      <div className="UserInfo__ImageWrap">
        <img className="UserInfo__Image" src="/generic-user.jpg"></img>
      </div>
      <div className="UserInfo__Info">
        <h1 className="UserInfo__DisplayName">{user?.displayName || t("Anonymous User")}</h1>
        {(user && (
          <ul className="UserInfo__Details">
            <li className="UserInfo__Details--Item">{t("Email")}: {user.mail}</li>
            <li className="UserInfo__Details--Item">{t("Login")}: {user.login}</li>
            <li className="UserInfo__Details--Item">{t("Member since")}: {(user as User).freeOne}</li>
            <li className="UserInfo__Details--Item">{t("Session expires at")}: {getJwtExpirationDate(getJwt())?.toLocaleTimeString()}</li>
          </ul>
        )) || null}
      </div>
    </div>
  );
};

export default UserInfo;
