import { User } from "../../types/user";
import api, { AbortControllerRef, getRefAbortControllerSetter } from "../api";
import { ApiResponse } from "./types";

type AuthenticateParams = {
  username: string;
  password: string;
};

type AuthenticateResponse =
  ApiResponse<{ Authenticate: User; }>;

const authenticate = async ({ params, abortRef }: {
  params: AuthenticateParams;
  abortRef?: AbortControllerRef;
}): Promise<{ user: User; authorization?: string; }> => {
  const res = await api.get<AuthenticateResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      func: "Authenticate",
      user: params.username,
      pass: params.password,
    },
    withCredentials: true,
  });

  if (res.data?.result?.Authenticate === undefined) {
    if (res.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  return {
    user: res.data.result.Authenticate,
    authorization: res.headers["authorization"],
  };
};

export default authenticate;
