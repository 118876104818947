import React, { useState, useMemo } from "react";
import GenresContext from "./GenresContext";
import { Genres } from "../../types/search";

interface GenresProviderProps {
  children: React.ReactNode;
}
function GenresProvider({ children }: GenresProviderProps) {
  const [genres, setGenres] = useState<Genres>([]);

  const genresContextValue = useMemo(() => ({
    genres,
    setGenres,
  }), [genres]);

  return (
    <GenresContext.Provider value={genresContextValue}>
      {children}
    </GenresContext.Provider>
  );
}

export default GenresProvider;
