import React from "react";
import { DownloadDetailed } from "../../api/implementations/types";
import "./index.scss";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const DownloadItem: React.FC<DownloadDetailed> = ({ title, author, noticeNr, downloadDate }) => {
  const navigate = useNavigate();
  return (
    <div
      className="DownloadItem"
      onClick={() => {
        navigate(`/book/${noticeNr}`);
      }}
      role="button"
      aria-labelledby={`download-item-title-${noticeNr}`}
      aria-describedby={`download-item-details-${noticeNr}`}
      aria-label={`${t("Go to the book page for")} ${title}`}
    >
      <h3 id={`download-item-title-${noticeNr}`} className="DownloadItem__Title">
        {title}
      </h3>
      <p className="DownloadItem__Author">{t("by ")}{author}</p>
      <p id={`download-item-details-${noticeNr}`} className="DownloadItem__Details">
        {t("Book Number:")} {noticeNr}<br />
        {t("Downloaded the ")} {new Date(downloadDate).toLocaleDateString()} {t("at")} {new Date(downloadDate).toLocaleTimeString()}
      </p>
    </div>
  )
};

export default DownloadItem;
