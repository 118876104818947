import { createContext } from "react";
import { Genres } from "../../types/search";
import { idLog } from "../../utils/references";

type NullableGenres = Genres;
export interface GenresContextType {
  genres: NullableGenres;
  setGenres: React.Dispatch<React.SetStateAction<NullableGenres>>;
}

const GenresContext = createContext<GenresContextType>({
  genres: [],
  setGenres: (prevState: boolean | React.SetStateAction<NullableGenres>) => console.log(
    ...idLog(
      { id: "WARNING ErrorContext:" },
      `not setting error ${prevState} wrap your consumer with a ErrorProvider`
    )
  ),
});

export default GenresContext;
