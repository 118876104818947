import React, { useCallback, useEffect, useMemo } from 'react';
import './index.scss';

export type CheckboxGroupOption = {
  value: string;
  label: string;
  selected: boolean;
};

type ComponentProps = {
  options: CheckboxGroupOption[];
  onChange: (selected: string[]) => void;
  title: string;
};

const CheckboxGroup: React.FC<ComponentProps> = ({ title, options, onChange }) => {
  const selectedOptions = useMemo(() => options.filter(o => o.selected).map(o => o.value), [options]);

  const handleCheckboxChange = useCallback((value: string) => {
    const updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    onChange(updatedOptions);
  }, [onChange, selectedOptions]);

  return (
    <div className="AccessibleCheckboxGroup__Container" role="group" aria-labelledby="checkbox-group-label">
      <h3 id="checkbox-group-label" className="AccessibleCheckboxGroup__Title">{title}</h3>
      {options.map((option, index) => (
        <div key={index} className="AccessibleCheckboxGroup__Checkbox">
          <input
            type="checkbox"
            id={`checkbox-${index}`}
            name={`checkbox-${index}`}
            className="AccessibleCheckboxGroup__Checkbox__Input"
            value={option.value}
            checked={selectedOptions.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
            aria-labelledby={`checkbox-label-${index}`}
          />
          <label
            id={`checkbox-label-${index}`}
            htmlFor={`checkbox-${index}`}
            className="AccessibleCheckboxGroup__Checkbox__Label"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
