import { useState, useEffect, useContext, useMemo, Dispatch, SetStateAction, useCallback } from "react";
import { AddRemove, ErrorContext, ErrorSource } from "../contexts/Error/ErrorContext";
import useApi from "../api/hooks/useApi";
import { anyOf } from "../utils/references";
import { UserContext } from "../contexts/User/UserContext";
import { AbortControllerRef } from "../api/api";

type ABTest<T> = (a: T|null, b: T|null) => boolean

const idx = ErrorSource.Book;

type UseResultReturn<T extends (arg0: { params: any, abortRef?: AbortControllerRef}) => any, K extends (keyof Awaited<ReturnType<T>>) & string> = {
  [P in K]: Awaited<ReturnType<T>>[P] | null;
} & {
  requestIfDifferentParams: Dispatch<SetStateAction<Parameters<T>[0]["params"] | null>>;
  request: (params: Parameters<T>[0]["params"]) => void;
  params: Parameters<T>[0]["params"] | null;
  isAwaitingResponse: boolean;
  clearApi: () => void;
  attemptRecoveryFromError: () => void;
  invalidated: boolean;
}

export enum AuthStyle {
  NO_AUTH_REFETCH="NO_AUTH_REFETCH", // does not require auth
  NO_AUTH_NULLIFY="NO_AUTH_NULLIFY",
  NO_AUTH_KEEP="NO_AUTH_KEEP",
}

const useResult = <
  T extends (arg0: { params: any, abortRef?: AbortControllerRef}) => any,
  P extends (keyof Awaited<ReturnType<T>>) & string
>(
  fetchResult: T,
  dataProp: P,
  paramsAreSame: ABTest<Parameters<T>[0]["params"]>,
  authStyle: AuthStyle,
  initialParams: Parameters<T>[0]["params"]|null = null
): UseResultReturn<T, P> => {
  const [params, setParams] = useState<Parameters<T>[0]["params"]|null>(initialParams);
  const {
    sourcedErrors,
    addOrRemoveSourcedError,
    removeFirstFromSource
  } = useContext(ErrorContext);
  const errorsFromHere = useMemo(() => (
    typeof sourcedErrors[idx] !== "undefined" ? sourcedErrors[idx] : []
  ), [sourcedErrors]);
  const { isJwtActive, transactionInProgress, userSetAt } = useContext(UserContext);
  const [result, setResult] = useState<Awaited<ReturnType<T>>[P] | null>(null);
  const [invalidated, setInvalidated] = useState<boolean>(false);

  const funcName = useMemo(() => {
    const res = fetchResult.toString().match(/funcName: "(\w+)"/g);
    if (res && res[0]) {
      return res[0].split(":")[1] || "";
    }
    return "unknown";
  }, [fetchResult]);

  const {
    exec,
    data,
    error,
    isIdle,
    isError,
    isPending,
    isSuccess,
    clearApi,
  } = useApi(fetchResult);

  useEffect(() => {
    const exitEarly = anyOf({
      NOT_params: !params,
      NOT_isIdle: !isIdle,
      transactionInProgress,
      isPending,
      errorCreatedByMe: errorsFromHere.length,
      requiresAuth_and_NOT_user: authStyle !== AuthStyle.NO_AUTH_REFETCH && !isJwtActive,
      result: (Boolean(result) && !invalidated),
    });

    if (exitEarly.bool) {
      return;
    }
    exec(params!);
  }, [params, isIdle, isJwtActive, invalidated, exec, result, errorsFromHere, authStyle, transactionInProgress]);

  useEffect(() => {
    if (authStyle !== AuthStyle.NO_AUTH_KEEP) {
      setInvalidated(true);
    }
    if (!isJwtActive && result !== null) {
      switch (authStyle) {
        case AuthStyle.NO_AUTH_REFETCH:
          setResult(null);
          break;
        case AuthStyle.NO_AUTH_NULLIFY:
          setResult(null);
          break;
        case AuthStyle.NO_AUTH_KEEP:
          break;
        default:
          // nothing
      }
    }
  }, [isJwtActive, authStyle]);

  useEffect(() => {
    if (!isError) {
      removeFirstFromSource(idx);
    } else if (error && errorsFromHere.findIndex((err) => err.error === error) === -1) {
      addOrRemoveSourcedError(idx, {
        error,
        date: new Date(),
      }, AddRemove.add);
    }
  }, [error, isError, addOrRemoveSourcedError, clearApi]);

  useEffect(() => {
    if (isSuccess && data) {
      setResult(data[dataProp]);
      setInvalidated(false);
      clearApi();
    }
  }, [data, isSuccess]);

  const updateParams = useCallback((newParams: Parameters<T>[0]["params"]) => {
    if (paramsAreSame(params, newParams)) return;
    setParams(newParams);
    setResult(null);
  }, [params, paramsAreSame]);

  return {
    [dataProp]: result,
    // requests only when params are different than prev params
    // use this for queries whose output does not change uless params change
    requestIfDifferentParams: updateParams,
    // requests regardless of prev params
    // use this for mutations or queries with chaning outputs
    request: (params: Parameters<T>[0]["params"]) => {
      setParams(params);
      setResult(null);
      setInvalidated(true);
    },
    params,
    isAwaitingResponse: isPending,
    clearApi,
    attemptRecoveryFromError: () => {
      removeFirstFromSource(idx);
      clearApi();
    },
    invalidated,
  } as UseResultReturn<T, P>;
};

export default useResult;
