import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Login from "../Login";
import { NewSearchProps } from "../../types/search";
import SearchContext from "../../contexts/Search/SearchContext";
import { getSearchRouteFromParams, toIntOr } from "../../utils/search";
import "./index.scss";
import SearchBar from "../SearchBar";
import { t } from "i18next";

type ComponentProps = {
  modifier?: "centered-logo" | "left-on-mobile";
}

const NavBar: React.FC<ComponentProps> = function ({ modifier = "left-on-mobile"}) {
  const navigate = useNavigate();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const { queryText, queryType, gatewayGenreCode, page, format, youth } = useContext(SearchContext);
  const [searchQuery, setSearchQuery] = useState<NewSearchProps>({
    queryText: queryText || "",
    queryType,
    page,
    gatewayGenreCode,
    format,
    youth,
    count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
  });

  return (
    <div className="Navbar__Container">
      <nav
        className={`Navbar__NavContainer Navbar__NavContainer--${modifier}`}
        aria-label={t("Main Navigation")}
      >
        <Link
          className="Navbar__LogoLink"
          aria-label={t("Home")}
          onClick={event => {
            event.preventDefault();
            const { youth, format } = searchQuery;
            setShowAdvancedSearch(false);
            navigate(getSearchRouteFromParams({
              page: 0,
              youth,
              format
            }));
          } }
          to={"#"}
        >
          <img
            className="Navbar__Logo"
            src={`/${import.meta.env.VITE_ORIGIN}-Logo.png`}
            alt={`Logo ${import.meta.env.VITE_ORIGIN_FULL}`}
          />
        </Link>
      </nav>
      <SearchBar
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setShowAdvancedSearch={setShowAdvancedSearch}
        showAdvancedSearch={showAdvancedSearch}
      />
      <aside className="Navbar__LoginContainer" role="complementary">
        <Login />
      </aside>
    </div>
  );
};

export default NavBar;
