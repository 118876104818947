import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { AudioUrlContext } from "./AudioUrlContext";

interface GenresProviderProps {
  children: React.ReactNode;
}

function play(
  audio: HTMLAudioElement,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) {
  audio.play();
  setIsPlaying(true);
  audio.onended = () => {
    setIsPlaying(false);
  };
}

function AudioProvider({ children }: GenresProviderProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const togglePlay = useCallback((url: string | null) => {
    if (url === null && audio === null) throw new Error("There is no global nor parameter audio: cannot toggle any");
    if ((url === null && audio !== null)) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        play(audio, setIsPlaying);
      }
    } else if (url !== null && audio === null) {
      if (isPlaying) throw new Error("Should not be playing and having audio null");
      const a = new Audio(url);
      play(a, setIsPlaying);
      setAudio(a);
    } else if (url !== null && audio !== null) {
      if (url !== audio.src) { // different, pause state one and play param one
        if (isPlaying) {
          audio.pause();
        }
        const a = new Audio(url);
        play(a, setIsPlaying);
        setAudio(a);
        // if same, toggle the state one
      } else if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        play(audio, setIsPlaying);
      }
    }
  }, [audio, isPlaying, setIsPlaying, setAudio]);

  const isUrlPlaying = useCallback(
    (url: string | null) => url !== null && audio !== null && url === audio.src && isPlaying,
    [audio, isPlaying]
  );

  const audioContextValue = useMemo(() => ({
    togglePlay,
    isPlaying: isUrlPlaying,
  }), [togglePlay, isUrlPlaying]);

  // Clean up the audio element on unmount
  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        setAudio(null);
      }
    };
  }, []);

  return (
    <AudioUrlContext.Provider value={audioContextValue}>
      {children}
    </AudioUrlContext.Provider>
  );
}

export default AudioProvider;
