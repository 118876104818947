import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { searchByGen } from "../../../utils/search";
import { I18NLabelHandle } from "../../../types/search";
import "./index.scss";

interface Fields {
  labelI18NHandle: I18NLabelHandle;
  value?: string|string[];
  underlined?: boolean;
  searchValue?: string;
}

function makeValueSearchValuePairs(searchValues: string[], values: string[]) {
  if (searchValues.length <= 0 && values.length <= 0) {
    throw new Error("Missing value or searchValue");
  } else if (searchValues.length <= 0) {
    return values.map(el => ({ searchValue: el, value: el }));
  } else if (values.length <= 0) {
    return searchValues.map(el => ({ searchValue: el, value: el }));
  } else if (searchValues.length !== values.length) {
    throw new Error("Arrays must be same length or one empty");
  } else {
    return searchValues.map((searchValue, i) => ({ searchValue, value: values[i]}));
  }
}

export default function BookInfoField({
  labelI18NHandle,
  value,
  searchValue,
  underlined,
}: Fields): JSX.Element {
  const navigate = useNavigate();
  const sureArraySearchValue = Array.isArray(searchValue) ? searchValue : (searchValue ? [searchValue] : []);
  const sureArrayValue = Array.isArray(value) ? value : (value ? [value] : []);
  const sureArray = useMemo(() => makeValueSearchValuePairs(sureArraySearchValue, sureArrayValue), [value, searchValue]);
  return (
    <dl className="BookInfoField__Container">
      <dt className="BookInfoField__Label">
        {t(labelI18NHandle)}
        {value === undefined || value.length === 0 ? "" : ": "}
      </dt>
      {sureArray.map(({ searchValue: term, value}, index, arr) => (
        <dd key={term}>
          <span
            className={`BookInfoField__Value${underlined ? " BookInfoField__Value--underlined" : ""}`}
            role={underlined ? "button" : undefined}
            tabIndex={underlined ? 0 : undefined}
            aria-hidden="false"
            aria-label={underlined
              ? `${t("Search for books whose")} ${t(labelI18NHandle)} ${t(arr.length > 1
                  ? "contains"
                  : "is"
                )}: "${term}"`
              : `${t(labelI18NHandle)} ${t("is")}: "${value}`}
            onClick={() => {
              if (underlined && term) searchByGen(navigate)(labelI18NHandle, term);
            }}
            onKeyDown={(e) => {
              if (underlined && term && (e.key === 'Enter' || e.key === ' ')) {
                e.preventDefault();
                searchByGen(navigate)(labelI18NHandle, term);
              }
            }}
          >
            {value}
          </span>
          {index < arr.length - 1 ? <span aria-hidden="true">,&nbsp;</span> : null}
        </dd>
      ))}
    </dl>
  );
}