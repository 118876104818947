import React, { useCallback, useContext, useEffect } from "react";
import useBookSearch from "src/hooks/useBookSearch";
import Pagination from "../Pagination";
import SearchContext from "../../contexts/Search/SearchContext";
import { ErrorSource, TimedError } from "../../contexts/Error/ErrorContext";
import useErrorManager from "../../hooks/useErrorManager";
import errorDsiplayHandleGen from "../../utils/errorHandler";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Books from "../Books";
import { useLocation } from "react-router-dom";
import { doParamsHaveAppliedFilters } from "../../utils/search";

type ComponentProps = {}

const BookResults: React.FC<ComponentProps> = ({}) => {
  const queryParams = useContext(SearchContext);
  const { queryText } = queryParams;

  const location = useLocation();

  const {
    books,
    isPending,
    totalCount,
    receivedCount,
    attemptRecoveryFromError,
    currentBooksValuesParam,
  } = useBookSearch();

  useEffect(() => {
    if (isPending) return;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isPending]);

  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => {
    return errorDsiplayHandleGen({ attemptRecoveryFromError })(props)
   }, [attemptRecoveryFromError]);

  useErrorManager(ErrorSource.BookGrid, makeErrorToAction);

  const { t } = useTranslation(["common"]);

  const isHome = !location.pathname.includes('/search');

  useDocumentTitle(`${!isHome
    ? `${((queryText && queryText !== "") ? `${t("Results for")}: "${queryText}"` : t("Books"))}${doParamsHaveAppliedFilters(queryParams) ? ` (${t("filters active")})` : ""}`
    : t("Home Page")}`
  );

  return (
    <Books
      title={
        !isPending
          ? `${((queryText && queryText !== "") ? `${t("Results for")}: "${queryText}"` : t("Books"))}${doParamsHaveAppliedFilters(queryParams) ? ` (${t("filters active")})` : ""}`
          : t("Loading books")
      }
      noBooksMesssageOrEl={
        (isHome && (
          <p>{t("No books yet.")}</p>
        )) || (
          <>
            {t("No books for the search ")}&quot;
            <span className="BookSearch__SearchTerm">{queryText}</span>
            &quot;
          </>
        )
      }
      books={books}
      isPending={isPending}
      onIsListItemFocusedChange={isFocused => isFocused}
    >
      <Pagination
        id="pagination"
        totalCount={totalCount}
        receivedCount={receivedCount}
        newSearchProps={currentBooksValuesParam}
      />
    </Books>
  );
}

export default BookResults;