import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import AdvancedSearch from "../AdvancedSearch";
import { useTranslation } from "react-i18next";
import { NewSearchProps } from "../../types/search";
import { useNavigate } from "react-router-dom";
import { getSearchRouteFromParams } from "../../utils/search";
import SearchButton from "../SearchButton";
import "./index.scss";

type ComponentProps = {
  searchQuery: NewSearchProps;
  setSearchQuery: React.Dispatch<React.SetStateAction<NewSearchProps>>;
  setShowAdvancedSearch: React.Dispatch<React.SetStateAction<boolean>>; showAdvancedSearch: boolean;
}

const SearchBar: React.FC<ComponentProps> = ({
  searchQuery,
  setSearchQuery,
  setShowAdvancedSearch,
  showAdvancedSearch,
}) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const advancedSearchRef = useRef<HTMLDivElement>(null);

  const handleSearch = () => {
    setShowAdvancedSearch(false);
    navigate(getSearchRouteFromParams(searchQuery));
  };

  useEffect(() => {
    if (showAdvancedSearch && advancedSearchRef.current) {
      advancedSearchRef.current?.focus();
    }
  }, [showAdvancedSearch]);

  return (
    <form
      className="SearchBar__Container"
      aria-label={typeof searchQuery.queryText !== "undefined" ? `${t("Search for books containing")} ${searchQuery.queryText}` : t("Get latest books")}
      onSubmit={event => {
        event.preventDefault();
      }}
    >
      <TextField
        id="outlined-search"
        label={t("What are you looking for ?")}
        type="search"
        aria-label={t("Search")}
        fullWidth
        value={searchQuery.queryText}
        onChange={(e) => {
          setSearchQuery((prevState) => ({
            ...prevState,
            queryText: e.target.value,
          }));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleSearch();
        }}
      />
      <SearchButton triggerSearch={handleSearch} />
      <div className="Navbar__AdvancedSearchWrap">
        <button
          className="Navbar__AdvancedSearchButton"
          onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
          aria-expanded={showAdvancedSearch}
          aria-controls="advanced-search-content"
          aria-label={t("Advanced Search")}
        >
          {t("Advanced Search")}
        </button>
        <div
          id="advanced-search-content"
          className="Navbar__AdvancedSearchContent"
          role="region"
          aria-hidden={!showAdvancedSearch}
          ref={advancedSearchRef}
          tabIndex={-1}
        >
          {showAdvancedSearch && (
            <AdvancedSearch
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              triggerSearch={handleSearch}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default SearchBar;