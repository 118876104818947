import React from 'react';
import './index.scss';

interface ComponentProps {
  onChange: (selected: boolean) => void;
  toggled: boolean;
  label: string;
  id: string;
}

const Switch: React.FC<ComponentProps> = ({ onChange, toggled, label, id }) => {
  return (
    <div className="Switch__Container">
      <label htmlFor={id} className="Switch__Label">{label}</label>
      <label htmlFor={id} className="Switch">
        <input
          id={id}
          type="checkbox"
          checked={toggled}
          onChange={(e) => onChange(e.target.checked)}
          role="switch"
          aria-checked={toggled}
          aria-labelledby={id}
        />
        <span className="Switch__Slider Switch__Slider--round"></span>
      </label>
    </div>
  );
};

export default Switch;
