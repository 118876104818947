import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Loading from "../../components/Loading";
import BookInfo from "../../components/BookInfo";
import BookSynopsis from "../../components/BookSynopsis";
import Footer from "../../components/Footer";
import ErrorModal from "../../components/ErrorModal";
import { ErrorSource, TimedError } from "../../contexts/Error/ErrorContext";
import useErrorManager from "../../hooks/useErrorManager";
import errorDsiplayHandleGen from "../../utils/errorHandler";
import Page from "../../components/Page";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import useResult, { AuthStyle } from "../../hooks/useResult";
import { GetBookParams, getBook } from "../../api/implementations/booksApi";
import { RemoteServiceId } from "../../api/implementations/types";

function paramsAreSame(a: GetBookParams | null, b: GetBookParams|null): boolean {
  if (a === null && b === null) return true;
  if (a === null || b === null) return false;
  const { code: bc, origin: bo } = b;
  const { code: ac, origin: ao } = a;
  return ac === bc && ao === bo;
}

export default function BookPage(): JSX.Element {
  const { bookCode } = useParams();
  const { book, isAwaitingResponse, attemptRecoveryFromError } = useResult(getBook, "book", paramsAreSame, AuthStyle.NO_AUTH_REFETCH, (bookCode && { code: bookCode, origin: RemoteServiceId.BBR }) || null);
  const { t } = useTranslation(["common"]);

  useDocumentTitle(`${book?.title || t("Unknown book")}`);

  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => (
    errorDsiplayHandleGen({ attemptRecoveryFromError })(props)
  ), [attemptRecoveryFromError]);

  useErrorManager(ErrorSource.Book, makeErrorToAction);

  return (
    <Page
      header={<NavBar />}
      main={((book && (
        <>
          <BookInfo book={book} />
          <BookSynopsis book={book} />
        </>
      )) || ((isAwaitingResponse || !book) && (
        <Loading />
      )))}
      footer={<Footer />}
    >
      <ErrorModal />
    </Page>
  );
}
