import React from "react";
import { RemoteServiceId } from "../../api/implementations/types";
import "./index.scss";

type RoundedLogoProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & {
  origin: RemoteServiceId
  size?: "small" | "big"
};

export default function RoundedLogo({ size, origin, ...rest }: RoundedLogoProps) {
  return (
    <div
      style={{ backgroundImage: `url(/${origin}-Logo-square.png)`}}
      className={`RoundedLogo${import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN
        ? " RoundedLogo--notClickable"
        : ""} RoundedLogo--${size || "big"}`}
      {...rest}
    >
    </div>
  );
};