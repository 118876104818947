import { createContext } from "react";
import { idLog } from "../../utils/references";
import { AxiosError } from "axios";

export enum ErrorSource {
  BookGrid,
  Book,
  Login,
  Genres,
}

export type TimedAxiosError = {
  error: AxiosError;
  date: Date;
};

export type TimedUnknownError = {
  error: Error;
  date: Date;
};

export type TimedError = TimedAxiosError | TimedUnknownError;

export function isTimedAxiosError(a: TimedError): a is TimedAxiosError {
  return typeof (a.error as AxiosError).response !== "undefined";
}

export enum AddRemove {
  add,
  remove,
}

export type SourcedErrors = {
  [k in ErrorSource]?: TimedError[];
};

export type ErrorToAction = {
  source: ErrorSource;
  timedError: TimedError;
  message: string;
  dismissAction?: () => void;
};

export type ErrorContextType = {
  sourcedErrors: SourcedErrors;
  shownErrorStack: ErrorToAction[];
  addOrRemoveSourcedError: (
    source: ErrorSource,
    timedError: TimedError | null,
    action: AddRemove
  ) => void;
  removeFirstFromSource: (source: ErrorSource) => void;
  show: (err: ErrorToAction) => void;
  dismissFirstFromSource: (source: ErrorSource) => void;
};

export const ErrorContext = createContext<ErrorContextType>({
  sourcedErrors: {},
  shownErrorStack: [],
  addOrRemoveSourcedError: (
    source: ErrorSource,
    timedError: TimedError | null,
    action: AddRemove
  ) => console.log(
    ...idLog(
      { id: "WARNING ErrorContext:" },
      `not setting error ${source}, ${timedError}, ${action} wrap your consumer with a ErrorProvider`
    )
  ),
  show: (err: ErrorToAction) => console.log(
    ...idLog(
      { id: "WARNING ErrorContext:" },
      `not setting error ${err} wrap your consumer with a ErrorProvider`
    )
  ),
  dismissFirstFromSource: (source: ErrorSource) => console.log(
    ...idLog(
      { id: "WARNING ErrorContext:" },
      `not setting error wrap ${source} your consumer with a ErrorProvider`
    )
  ),
  removeFirstFromSource: (source: ErrorSource) => console.log(
    ...idLog(
      { id: "WARNING ErrorContext:" },
      `not setting error wrap ${source} your consumer with a ErrorProvider`
    )
  ),
});

export default ErrorContext;
