import { createContext } from "react";
import { User } from "../../types/user";
import { idLog } from "../../utils/references";

export type UserContextType = {
  user: User | null;
  isJwtActive: boolean;
  userSetAt: Date | null;
  setUser: (user: User | null) => void;
  transactionInProgress: boolean;
  setTransactionInProgress: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  userSetAt: null,
  isJwtActive: false,
  transactionInProgress: false,
  setUser: (u: User | null) => console.log(
    ...idLog(
      { id: "WARNING UserContext:" },
      `not setting user ${u} wrap your consumer with a UserProvider`
    )
  ),
  setTransactionInProgress: (prevState: boolean | React.SetStateAction<boolean>) => console.log(
    ...idLog(
      { id: "WARNING UserContext:" },
      `not setting transactionInProgress ${prevState} wrap your consumer with a UserProvider`
    )
  ),
});
