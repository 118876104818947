import React, { useContext } from "react";
import "./index.scss";
import { MdDownloadDone, MdOutlineDownload } from "react-icons/md";
import { UserContext } from "../../contexts/User/UserContext";
import { BookDownloadFiles } from "../../types/book";
import { getBookDlUri, isBookDownloadable } from "../../utils/authedBooks";

type RoundedLogoProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
  & {
  book: { files: Partial<BookDownloadFiles>; };
  size?: "card" | "full"
};

export default function DownloadButton({
  size,
  book,
  ...rest
}: RoundedLogoProps) {
  const { user } = useContext(UserContext);
  return user !== null && isBookDownloadable(book)
    ? (
      <a
        className={`DownloadButton DownloadButton--${size || "big"} ActionButton`}
        {...rest}
        href={getBookDlUri(book)}
        onClick={(e) => {
          if (!user) e.preventDefault();
        }}
      >
        {user ? <MdOutlineDownload aria-hidden="true" /> : <MdDownloadDone aria-hidden="true" /> }
      </a>
    )
    : null;
};