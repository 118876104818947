import { useEffect } from 'react';

const useDocumentTitle = (title: string) => {
  const sanitized = title.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  useEffect(() => {
    document.title = `${sanitized} | ${import.meta.env.VITE_APP_TITLE}`;
  }, [sanitized]);
};

export default useDocumentTitle;
