import { Book } from "../../types/book";
import { BooksResult, Format, ValuesParam, YouthText } from "../../types/search";
import { applyOptionallyForcedOrigin } from "../../utils/search";
import api, { AbortControllerRef, getRefAbortControllerSetter } from "../api";
import { ApiResponse, DownloadDetailed, RemoteServiceId, WishResult } from "./types";

type AuthenticateResponse = ApiResponse<{
  NewSearch: {
    [k: number]: Book;
    count: number;
    facets: {
      facets: [ ],
      highlighting: [ ],
      spelling: [ ]
    }
  };
}>;

const isBookEntry = (item: any): item is Book => typeof item !== "number";

export type GetBookParams = { code: string; origin: RemoteServiceId };
export const getBook = async ({ params: { code, origin }, abortRef } : {
  params: GetBookParams;
  abortRef?: AbortControllerRef;
}): Promise<{ book: Book; }> => {
  const res = await api.get<AuthenticateResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: "NewSearch",
      values: JSON.stringify({
        queryText: "",
        code,
        origin,
        format: Format.all,
        ...applyOptionallyForcedOrigin()
      }),
    },
    withCredentials: true,
  });

  if (res?.data?.result?.NewSearch === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  const bookEntry = Object.values(res.data.result.NewSearch)
    .filter(isBookEntry)
    .filter((item: Book) => (item.code === code && item.origin === origin))[0];

  if (typeof bookEntry === "undefined") {
    throw new Error("Book Not Found");
  }

  return {
    book: bookEntry,
  };
};

type GetDownloadsResponse = ApiResponse<{
  GetDownloads: {
    [k: number]: DownloadDetailed;
  };
}>;

export type GetDownloadsParams = {
  start: number;
  count: number;
  fromInterface?: string;
};

export const getDownloads = async ({ params, abortRef }: {
  params: GetDownloadsParams;
  abortRef?: AbortControllerRef;
}): Promise<{ downloads: DownloadDetailed[]; }> => {
  const res = await api.get<GetDownloadsResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: "GetDownloads",
      ...params,
    },
    withCredentials: true,
  });

  if (res?.data?.result?.GetDownloads === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  return {
    downloads: Object.values(res.data.result.GetDownloads),
  };
};

type GetWishesResponse = ApiResponse<{
  GetWishes: {
    [k: number]: WishResult;
  };
}>;

export type GetWishesParams = {
  start: number;
  count: number;
  origin?: RemoteServiceId;
};

export const getWishes = async ({ params, abortRef }: {
  params: GetWishesParams;
  abortRef?: AbortControllerRef;
}): Promise<{ wishes: WishResult[]; }> => {
  const res = await api.get<GetWishesResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: 'GetWishes',
      ...params,
    },
    withCredentials: true,
  });

  if (res?.data?.result?.GetWishes === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  return {
    wishes: Object.values(res.data.result.GetWishes),
  };
};

export type AddWishParams = {
  code: string;
  origin: RemoteServiceId;
}

type AddWishResponse = ApiResponse<{
  AddWish: {
    success: number;
  };
}>;

export const addWish = async ({ params, abortRef }: {
  params: AddWishParams;
  abortRef?: AbortControllerRef;
}): Promise<{ success: boolean; }> => {
  const res = await api.get<AddWishResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: 'AddWish',
      ...params,
    },
    withCredentials: true,
  });

  if (res?.data?.result?.AddWish === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  return {
    success: typeof res.data.result.AddWish.success === "number",
  };
};

export type DeleteWishParams = {
  code: string;
  origin: RemoteServiceId;
}

type DeleteWishResponse = ApiResponse<{
  DeleteWish: {
    success: number;
  };
}>;

export const deleteWish = async ({ params, abortRef }: {
  params: AddWishParams;
  abortRef?: AbortControllerRef;
}): Promise<{ success: boolean; }> => {
  const res = await api.get<DeleteWishResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: 'DeleteWish',
      ...params,
    },
    withCredentials: true,
  });

  if (res?.data?.result?.DeleteWish === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  return {
    success: typeof res.data.result.DeleteWish.success === "number",
  };
};

export const getBooks = async ({ params, abortRef }: {
  params: ValuesParam;
  abortRef?: AbortControllerRef;
}): Promise<BooksResult> => {
  const { youth, ...rest } = params;
  const finalParams = (!youth || youth === YouthText.no)
    ? rest
    : {
      ...rest,
      jeunesse: {"filtrer": "filtrer"},
    };
  const res = await api.get<AuthenticateResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: "NewSearch",
      values: JSON.stringify({ ...finalParams, ...applyOptionallyForcedOrigin() }),
    },
    withCredentials: true,
  });

  if (res?.data?.result?.NewSearch === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  const { count, facets, ...books } = res.data.result.NewSearch;
  const booksArray = Object.values(books);

  return {
    books: booksArray,
    totalCount: count,
    receivedCount: booksArray.length,
  };
};
