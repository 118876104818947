import { Books } from "./book";

export enum QueryType {
  text="text",
  author="author",
  reader="reader",
  title="title",
}

export enum Format {
  daisy="daisy",
  brf="brf",
  all="all",
};

export enum YouthText {
  yes="y",
  no="_",
}

export type ValuesParam = {
  page: number;
  count: number;
  queryType?: QueryType;
  queryText?: string;
  gatewayGenreCode?: string;
  format?: Format;
  youth?: YouthText;
};

export type BooksResult = {
  books: Books;
  totalCount: number;
  receivedCount: number;
};

export interface NavSearchProps {
  queryText?: string;
  page: number;
  queryType?: QueryType;
  gatewayGenreCode?: string;
  format?: Format;
  youth?: YouthText;
}

export interface NewSearchProps extends NavSearchProps {
  count: number;
  code?: string;
  origin?: string;
}

export interface Genre {
  code: string;
  text: string;
}

export enum I18NLabelHandle {
  AudioBook = "AudioBook",
  NoticeNr = "NoticeNr",
  BookNr = "Book Number",
  Genre = "Genre",
  Tags = "Tags",
  Format = "Format",
  EditorLocation = "EditorLocation",
  Editor = "Editor",
  Producer = "Producer",
  ISBN = "ISBN",
  Author = "Author",
  Duration = "Duration",
  Origin = "Origin",
  PublishedOn = "PublishedOn",
  Reader = "Reader",
  Youth = "Youth",
}

export interface Genres extends Array<Genre> {}
