import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Copyright from "../MeowFooter/Copyright";

type ComponentProps = {
  modifier?: "vertical-line"
}

export default function Footer({ modifier }: ComponentProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  return (
    <footer className={`Footer${modifier ? ` Footer--${modifier}`: ''}`} role="contentinfo">
      <div className={`Footer__SplitContainer`}>
        <div className="Footer__First" aria-hidden="true"></div>
        <div className="Footer__Content">
          <p>
            <span className="Footer__Description">{import.meta.env.VITE_ORIGIN_FULL}</span>
          </p>
          <p>
            <span className="Footer__Value">{import.meta.env.VITE_ENTITY_ADDRESS}</span>
          </p>
          <p>
            <span className="Footer__Description">{t("Phone")}</span>:
            <a
              href={`tel:${import.meta.env.VITE_ENTITY_PHONE}`}
              className="Footer__ValueLink"
              aria-label={`${t("Phone")}: ${import.meta.env.VITE_ENTITY_PHONE}`}
            >
              {import.meta.env.VITE_ENTITY_PHONE}
            </a>,
            <span className="Footer__Description"> {t("Email")}</span>:
            <a
              href={`mailto:${import.meta.env.VITE_ENTITY_EMAIL}`}
              className="Footer__ValueLink"
              aria-label={`${t("Email")}: ${import.meta.env.VITE_ENTITY_EMAIL}`}
            >
              {import.meta.env.VITE_ENTITY_EMAIL}
            </a>
          </p>
          <p>
            <span className="Footer__Value"></span>
            <span className="Footer__Value">{import.meta.env.VITE_ENTITY_NAME}.</span>
            <span className="Footer__Value">{t("All rights reserved")}.</span>
          </p>
          <div className="Footer__Credits">
            <Copyright />
          </div>
        </div>
      </div>
    </footer>
  );
}
