import { createContext } from "react";
import { idLog } from "../../utils/references";
import { toIntOr } from "../../utils/search";
import { ValuesParam } from "../../types/search";

export type SearchContextType = {}
  & ValuesParam
  & {
    setPage: React.Dispatch<React.SetStateAction<ValuesParam["page"]>>;
    setCount: React.Dispatch<React.SetStateAction<ValuesParam["count"]>>;
    setQueryType: React.Dispatch<React.SetStateAction<ValuesParam["queryType"]>>;
    setQueryText: React.Dispatch<React.SetStateAction<ValuesParam["queryText"]>>;
    setGatewayGenreCode: React.Dispatch<React.SetStateAction<ValuesParam["gatewayGenreCode"]>>;
    setFormat: React.Dispatch<React.SetStateAction<ValuesParam["format"]>>;
    setYouth: React.Dispatch<React.SetStateAction<ValuesParam["youth"]>>;
  };

const SearchContext = createContext<SearchContextType>({
  page: 0,
  setPage: (prevState: boolean | React.SetStateAction<ValuesParam["page"]>) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting page ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
  count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
  setCount: (prevState: boolean | React.SetStateAction<ValuesParam["count"]>) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting count ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
  setQueryText: (prevState: boolean | React.SetStateAction<ValuesParam["queryText"]>) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting queryText ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
  setQueryType: (prevState: boolean | React.SetStateAction<ValuesParam["queryType"]>) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting queryType ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
  setGatewayGenreCode: (
    prevState: boolean | React.SetStateAction<ValuesParam["gatewayGenreCode"]>
  ) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting gatewayGenreCode ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
  setFormat: (
    prevState: boolean | React.SetStateAction<ValuesParam["format"]>
  ) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting format ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
  setYouth: (
    prevState: boolean | React.SetStateAction<ValuesParam["youth"]>
  ) => console.log(
    ...idLog(
      { id: "WARNING SearchContext:" },
      `not setting youth ${prevState} wrap your consumer with a SearchProvider`
    )
  ),
});

export default SearchContext;
