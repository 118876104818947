import { useCallback, useEffect, useRef, useState } from "react";
import useApiStatus from "./useApiStatus";
import { RequestStatus } from "../constants/apiStatus";
import type { AbortControllerRef } from "../api";
import { generateSimpleHash } from "../../utils/randomHash";

export type ApiFunction = (arg0: { params: any; abortRef: AbortControllerRef }) => Promise<any>;

const id = generateSimpleHash();

function useApi<F extends ApiFunction>(fn: F) {
  const [data, setData] = useState<null | Awaited<ReturnType<F>>>(null);
  const [error, setError] = useState<null | Error>(null);
  const { apiStatus, setApiStatus, isPending, ...normalizedStatuses } = useApiStatus();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [callParams, setCallParams] = useState<any>();

  useEffect(() => () => abortControllerRef.current?.abort(), []);
  useEffect(() => {
    if (isPending) {
      (async function makeTheCallOnceIsPendingStateIsSetToPreventMultipleCalls() {
        try {
          setData(await fn({ params: callParams, abortRef: abortControllerRef }));
          setApiStatus(RequestStatus.SUCCESS);
        } catch (err) {
          setError(err instanceof Error ? err : new Error(String(err)));
          setApiStatus(RequestStatus.ERROR);
        }
      }());
    } else if (callParams !== undefined) {
      setCallParams(undefined);
    }
  }, [isPending]);

  const exec = useCallback(async (params: Parameters<F>[0]["params"]) => {
    if (isPending) {
      return;
    }
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    setError(null);
    setApiStatus(RequestStatus.PENDING);
    setCallParams(params);
  }, [fn, setError, setApiStatus, setData, setCallParams]);

  const clearApi = useCallback(() => {
    setError(null);
    setApiStatus(RequestStatus.IDLE);
    setData(null);
  }, [setApiStatus, setData, setError, abortControllerRef]);

  const abort = useCallback(() => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = null; // Optionally reset the controller after aborting
  }, []);

  return {
    data,
    apiStatus,
    error,
    exec,
    clearApi,
    abort,
    ...normalizedStatuses,
    isPending,
  };
}

export default useApi;
