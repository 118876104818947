import React, { CSSProperties } from "react";
import "./index.scss";

type ComponentProps = {
  style?: CSSProperties;
  colorScheme?: 'onBlack' | 'onWhite' | 'bWOnBlack' | 'bWOnWhite';
}

const CompanyLogo: React.FC<ComponentProps> = ({ colorScheme }) => (
  <a key="Meow__LogoLink" href="https://meow.ch" className={`Meow__LogoLink Meow__LogoLink--${colorScheme === undefined ? 'onWhite' : colorScheme}`}>
    &nbsp;
  </a>
);

export default CompanyLogo;