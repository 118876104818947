import React, { useEffect, useState } from 'react';
import './index.scss'; // Importing the CSS

const AnimatedVerticalLine = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // Trigger the animation after the component has mounted
        setAnimate(true);
    }, []);

    return (
        <div className={animate ? 'VerticalLine' : undefined} />
    );
};

export default AnimatedVerticalLine;
