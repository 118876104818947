import React from "react";
import "./index.scss";

type ComponentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & { children: React.ReactNode; }
  & { header: React.ReactNode; }
  & { main: React.ReactNode; }
  & { footer: React.ReactNode; }

export default function Page({ children, header, main, footer, ...rest }: ComponentProps) {
  return (
    <div className="Page">
      <div className="Page__Container" {...rest}>
        <header role="banner">
          {header}
        </header>
        <main role="main">
          {main}
        </main>
        <footer role="contentinfo">
          {footer}
        </footer>
        {children}
      </div>
    </div>
  );
}