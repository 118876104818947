import { useEffect, useRef } from 'react';

/**
 * useInterval - A custom hook that sets up an interval and clears it after unmounting.
 * It's an encapsulation of setInterval that automatically handles cleanup and lifecycle issues.
 *
 * @param callback - The callback function to execute after each delay interval.
 * @param delay - The delay in milliseconds between each execution of the callback. Pass null to pause the interval.
 * @param immediate - If true, the callback executes immediately on mount before starting the interval.
 */
function useInterval(callback: () => void, delay: number | null, immediate: boolean = false): void {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (immediate && delay !== null) {
      tick();  // If immediate is true, trigger the function immediately on setup
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay, immediate]);
}

export default useInterval;
