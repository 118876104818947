import React, { useCallback } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading";
import { ErrorSource, TimedError } from "../../contexts/Error/ErrorContext";
import errorDsiplayHandleGen from "../../utils/errorHandler";
import useErrorManager from "../../hooks/useErrorManager";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";

const Login: React.FC = function Login() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const location = useLocation();
  const isAccountRoute = location.pathname.includes('/account');
  const {
    disconnect,
    user,
    username,
    password,
    setUsername,
    setPassword,
    isAwaitingResponse,
    isTransactionInProgress,
    triggerSubmit,
    setPrevent,
    prevent: isAnyInputFocused,
    attemptRecoveryFromError,
  } = useAuth();

  const makeErrorToAction = useCallback((props: {
    dismiss: () => void,
    timedError: TimedError;
    source: ErrorSource;
  }) => {
    const errToAction = errorDsiplayHandleGen({ attemptRecoveryFromError })(props);
    if (errToAction.timedError.error.message === "Request failed with status code 400") {
      return {
        ...errToAction,
        source: ErrorSource.Login,
        message: t("Bad credentials"),
        dismissAction: () => {
          props.dismiss();
        },
      };
    }
    return errToAction;
  }, [attemptRecoveryFromError]);

  useErrorManager(ErrorSource.Login, makeErrorToAction);

  return (
    <div
      className={`Login__Container Login__Container--${!user ? "disconnected" : "connected"}`}
      tabIndex={0}
    >
      <h2 id="login-widget-title" className="visually-hidden">{t("Login Widget")}</h2>
      {((isAwaitingResponse || isTransactionInProgress) && (
        <Loading />
      )) || ((!user && (
        <form onSubmit={e => e.preventDefault()}>
          <div className="Login__InputContainer">
            <TextField
              id="outlined-username-input"
              label={t("Username")}
              type="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              onFocus={() => !isAnyInputFocused && setPrevent(true)}
              onBlur={() => isAnyInputFocused && setPrevent(false)}
              autoComplete="username"
              aria-required="true"
              fullWidth
            />
          </div>
          <div className="Login__InputContainer">
            <TextField
              id="outlined-password-input"
              label={t("Password")}
              type="password"
              value={password}
              onChange={(e) => { setPassword(e.target.value); }}
              onFocus={() => !isAnyInputFocused && setPrevent(true)}
              onBlur={() => isAnyInputFocused && setPrevent(false)}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                triggerSubmit();
              }}
              aria-required="true"
              autoComplete="current-password"
              fullWidth
            />
          </div>
          <button className="Login__LoginButton"
            disabled={isAwaitingResponse}
            onClick={triggerSubmit}
            aria-busy={isAwaitingResponse}
          >
            {t("Login")}
          </button>
        </form>
      )) || (
          (isAccountRoute && (
          <>
            <p
              className={`AccountLink AccountLink--disabled`}
            >
              {t("common:Welcome")}
              {" "}
              {user?.displayName}
            </p>
            <button className="Login__DisconnectButton" onClick={() => {
                setPrevent(true);
                disconnect();
              }}
            >
              {t("Disconnect")}
            </button>
          </>
          )) || (
            <div className={`AccountLink AccountLink--enabled`}>
              <a
                className={`AccountLink__Badge AccountLink__Badge--enabled`}
                onClick={() => navigate("/account")}
                role="button"
                aria-label={t("Go to user account page")}
              >
                {<MdAccountCircle />}
              </a>
              <span className={`AccountLink__Username`}>{user?.displayName}</span>
            </div>
          )
      ))}
    </div>
  );
};


export default Login;
