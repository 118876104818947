import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { BookReducedInfo } from "../../types/book";
import RoundedLogo from "../RoundedLogo";
import { searchByAuthorGen, searchByOriginGen } from "../../utils/search";
import BookCover from "../BookCover";
import CornerFormatPlayer from "../CornerFormatPlayer";
import getBookCover from "../../utils/cover";
import "./index.scss";
import WishButton from "../WishButton";
import { RemoteServiceId } from "../../api/implementations/types";
import DownloadButton from "../DownloadButton";
import { t } from "i18next";
import { ucfirst } from "../../utils/string";
import { NavigationKeys } from "../../types";

const isBBROnly = import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN && import.meta.env.VITE_ORIGIN === RemoteServiceId.BBR;

type ComponentProps = {
  book: BookReducedInfo;
  defaultWished?: boolean;
  size?: "card" | "miniature";
  onWishChange?: (prop: { nextWished: boolean; code: string; origin: RemoteServiceId; }) => void;
  cardActive: boolean;
  bookListIndex?: number;
  giveBackFocus: () => void;
};

function focusNextPrev(event: React.KeyboardEvent, className: string, setFocusedActionIndex: (idx: number) => void) {
  const actionButtons = event.currentTarget.querySelectorAll(`.${className}`);
  const currentIndex = getElementIndex(actionButtons, event);
  const nextIndex = (event.key === NavigationKeys.ArrowRight)
    ? (currentIndex + 1) % actionButtons.length
    : (
      (currentIndex > 0)
        ? currentIndex - 1
        : actionButtons.length - 1
    );
  setFocusedActionIndex(nextIndex);
}

function getElementIndex(actionButtons: NodeListOf<Element>, event: React.KeyboardEvent) {
  return Array.prototype.indexOf.call(actionButtons, event.target);
}

const getMetaImage = (url: string, cb: any) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

function BookCard({
  book,
  onWishChange,
  cardActive,
  bookListIndex,
  giveBackFocus,
  defaultWished = false,
  size = "card"
}: ComponentProps): JSX.Element {
  const navigate = useNavigate();
  const [bookCover, setBookCover] = useState(book.cover);
  const [focusedActionIndex, setFocusedActionIndex] = useState<null | number>(null);
  const bookCardId = `book-card-${book.code}${book.origin}`;

  useEffect(() => {
    getMetaImage(book.cover, (err: any, img: any) => {
      if (err || img.width < 10 || img.height < 10) {
        setBookCover(getBookCover());
      }
    });
  }, [book]);

  useEffect(() => {
    if (!cardActive) return;
    const cardEl = document.getElementById(bookCardId);
    if (cardEl && document.activeElement !== cardEl) cardEl?.focus();
  }, [cardActive]);

  const getActionButtons = () => {
    const cardEl = document.getElementById(bookCardId);
    if (!cardEl) return;
    const actionButtons = cardEl.querySelectorAll(`.ActionButton`);
    return actionButtons;
  };

  useEffect(() => {
    if (!cardActive || focusedActionIndex === null) return;
    const actionButtons = getActionButtons();
    if (!actionButtons) {
      return;
    }
    (actionButtons[focusedActionIndex] as HTMLElement)?.focus();
  }, [focusedActionIndex, cardActive, bookCardId]);

  const handleActionKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (!cardActive) return;
    switch (event.key) {
      case NavigationKeys.ArrowLeft:
      case NavigationKeys.ArrowRight:
        event.preventDefault();
        focusNextPrev(event, 'ActionButton', setFocusedActionIndex);
        break;
      case NavigationKeys.Escape:
        event.preventDefault();
        giveBackFocus();
        break;
      case NavigationKeys.Space:
        event.preventDefault();
        const actionButtons = getActionButtons();
        if (!actionButtons) return;
        (event.target as HTMLElement)?.click();
        break;
      default:
        break;
    }
  }, [focusedActionIndex, setFocusedActionIndex, giveBackFocus]);

  const setFocusedActionIfDifferent = (event: React.FocusEvent) => {
    const actionButtons = getActionButtons();
    if (!actionButtons) return;
    const currentIndex = Array.prototype.indexOf.call(actionButtons, event.target);
    if (currentIndex !== focusedActionIndex) {
      setFocusedActionIndex(currentIndex);
    }
  };

  return (
    <div
      id={bookCardId}
      className="BookCard__Container"
      tabIndex={0}
      aria-label={`${t("Book")}${bookListIndex !== undefined ? `, "${bookListIndex + 1}"` : ""}: "${book.title}", ${t("written by")}: "${book.author}". ${ucfirst(t("blurb"))} : ${book.summary}`}
      aria-describedby={`book-${book.code}-instructions`}
      onKeyDown={handleActionKeyDown}
    >
      <CornerFormatPlayer
        book={book}
        size={size}
        tabIndex={-1}
        onFocus={setFocusedActionIfDifferent}
      >
        <WishButton
          size={'card'}
          code={book.code}
          wished={book?.wished || defaultWished}
          origin={book.origin}
          aria-hidden="false"
          title={book.title}
          tabIndex={-1}
          onFocus={setFocusedActionIfDifferent}
          onWishChange={onWishChange ? onWishChange : undefined}
        />
        <DownloadButton
          size={'card'}
          book={book}
          aria-label={`${t("Download")} "${book.title}"`}
          aria-hidden="false"
          tabIndex={-1}
          onFocus={setFocusedActionIfDifferent}
        />
        <BookCover
          onClick={() => {
            navigate(`/book/${book.code}`);
          }}
          tabIndex={-1}
          cover={bookCover}
          aria-hidden="true"
        />
        {(!isBBROnly && (
          <RoundedLogo
            size="small"
            onClick={(e) => {
              if (import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN) {
                e.preventDefault();
                return;
              }
              searchByOriginGen(navigate)(book.origin);
            }}
            origin={book.origin}
            tabIndex={-1}
            role="button"
            aria-label={`${t("Search for more books produced by the")} "${book.origin}"`}
            onFocus={setFocusedActionIfDifferent}
          />
        )) || null}
      </CornerFormatPlayer>
      <div className="BookCard__TextContainer">
        <p className="BookCard__Author ActionButton"
          onClick={() => {
            searchByAuthorGen(navigate)(book.author);
          }}
          tabIndex={-1}
          role="button"
          aria-label={`${t("Search for books written by")} "${book.author}"`}
          onFocus={setFocusedActionIfDifferent}
        >
          {book.author}
        </p>
        <p className={`BookCard__Title${isBBROnly ? " BookCard__Title--bbr" : ""} ActionButton`}
          onClick={() => {
            navigate(`/book/${book.code}`);
          }}
          onFocus={setFocusedActionIfDifferent}
          tabIndex={-1}
          role="button"
          aria-label={`${t("Go to full page for the book")}: "${book.title}".`}
        >
          {book.title}
        </p>
      </div>
      <div id={`book-${book.code}-instructions`} className="visually-hidden">
        {`${t("Press Enter or Space to view details for")} "${book.title}"`}
      </div>
    </div>
  );
}

export default BookCard;
