import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useMatch, Navigate, NavLink, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import DownloadItemList from "../../components/DownloadItemList";
import ErrorModal from "../../components/ErrorModal";
import Page from "../../components/Page";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import UserInfo from "../../components/UserInfo";
import "./index.scss";
import WishItemList from "../../components/WishItemList";
import { UserContext } from "../../contexts/User/UserContext";
import AnimatedVerticalLine from "../../components/AnimatedVerticalLine";
import { ucfirst } from "../../utils/string";

enum TabName {
  wishes="wishes",
  downloads="downloads",
}

export default function UserAccountPage(): JSX.Element {
  const { user } = useContext(UserContext);
  const { t } = useTranslation(["common"]);
  const match = useMatch("account/*");
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active tab index
  const tabsRef = useRef<(HTMLAnchorElement|null)[]>([]);
  const navigate = useNavigate();
  const tabs = [TabName.wishes, TabName.downloads]; // Mock data for tabs

  useEffect(() => {
    tabsRef.current[activeIndex]?.focus();
  }, [activeIndex]);

  useEffect(() => {
    match?.pathname
    const tabName = match?.pathname.split(`${match.pathnameBase}/`)[1];
    setActiveIndex(tabs.indexOf((tabName || TabName.wishes) as TabName));
  }, [match, tabs]);

  useDocumentTitle(`${user?.displayName || t("Anonymous")}`);
  const activeTabName = location.pathname.includes(TabName.downloads)
    ? TabName.downloads
    : TabName.wishes;

  return (
    <Page
      header={<NavBar modifier="centered-logo" />}
      main={
        <div className="UserAccount__SplitContainer">
          <AnimatedVerticalLine />
          <UserInfo />
          <div className="UserAccount__Content">
            <nav
              className="Tab__Container"
              role="tablist"
              aria-label={t("User Account Sections Tabs")}
              onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                if (event.key === 'ArrowRight') {
                  const nextTab = (activeIndex + 1) % tabs.length; // Wrap around to the first tab
                  navigate(`/account/${tabs[nextTab]}`)
                } else if (event.key === 'ArrowLeft') {
                  const prevTab = (activeIndex - 1 + tabs.length) % tabs.length; // Wrap around to the last tab
                  navigate(`/account/${tabs[prevTab]}`)
                }
              }}
            >
              {tabs.map((tabName, index) => (
                <NavLink
                  ref={el => tabsRef.current[index] = el} // Adding refs to each tab
                  key={tabName}
                  to={`${match?.pathnameBase}/${tabName}`}
                  className={({ isActive }) => "Tab__Item" + (isActive ? " active" : "")}
                  role="tab"
                  aria-selected={activeTabName === tabName}
                  tabIndex={activeIndex === index ? 0 : -1}
                  id={`tab-${tabName}`}
                  aria-controls={`tabpanel-${tabName}`}
                >
                  {t(ucfirst(tabName))}
                </NavLink>
              ))}
            </nav>
            <Routes>
              <Route path="/" element={<Navigate replace to={TabName.wishes} />} />
              <Route
                path={TabName.wishes}
                element={
                  <WishItemList
                    id={`tabpanel-${TabName.wishes}`}
                    role="tabpanel"
                    ariaLabelledby={`tab-${TabName.wishes}`}
                    tabindex={activeIndex === tabs.findIndex(v => v === TabName.wishes) ? 0 : -1}
                    hidden={activeIndex !== tabs.findIndex(v => v === TabName.wishes)}
                  />
                }
              />
              <Route
                path={TabName.downloads}
                element={
                  <DownloadItemList
                    id={`tabpanel-${TabName.downloads}`}
                    role="tabpanel"
                    ariaLabelledby={`tab-${TabName.downloads}`}
                    tabindex={activeIndex === tabs.findIndex(v => v === TabName.downloads) ? 0 : -1}
                    hidden={activeIndex !== tabs.findIndex(v => v === TabName.downloads)}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      }
      footer={<Footer modifier="vertical-line" />}
    >
      <ErrorModal />
    </Page>
  );
}
