import React, { useState, useMemo } from "react";
import BooksContext from "./BooksContext";
import { Books } from "../../types/book";

interface BooksProviderProps {
  children: React.ReactNode;
}

function BooksProvider({ children }: BooksProviderProps) {
  const [books, setBooks] = useState<Books | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [receivedCount, setReceivedCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const booksContextValue = useMemo(() => ({
    books,
    setBooks,
    totalCount,
    setTotalCount,
    receivedCount,
    setReceivedCount,
    searchTerm,
    setSearchTerm,
  }), [books, totalCount, receivedCount, searchTerm]);

  return (
    <BooksContext.Provider value={booksContextValue}>
      {children}
    </BooksContext.Provider>
  );
}

export default BooksProvider;
