import { useCallback, useContext, useEffect, useState } from "react";
import { AddRemove, ErrorContext, ErrorSource, ErrorToAction, TimedError } from "../contexts/Error/ErrorContext";
import { sameNotUndefinedTimedErrors } from "../contexts/Error/ErrorProvider";

export type ErrorDisplayHandleGen = (props: {
  dismiss: () => void;
  timedError: TimedError;
  source: ErrorSource;
}) => ErrorToAction;

const DUMMY_ERROR_TO_ACTION = {
  timedError: null,
  source: null,
};

const useErrorManager = (errorSource: ErrorSource, errorActionMaker: ErrorDisplayHandleGen) => {
  const {
    sourcedErrors,
    show,
    addOrRemoveSourcedError,
    shownErrorStack
  } = useContext(ErrorContext);
  // error from errorSource
  const mySourcedErrors = sourcedErrors[errorSource];
  const mySourcedError = mySourcedErrors ? mySourcedErrors[0] : undefined;
  // top of the shown errors stack (can be any from errorSource)
  const [topOfShownErrorStack] = shownErrorStack;
  // get top of stack error's primitive values to prevent useEffect rerenders
  // DUMMY when not for us or not existent
  const {
    source: topSESSource,
    timedError: topSESTimedError,
  } = topOfShownErrorStack?.source === errorSource
    ? topOfShownErrorStack
    : DUMMY_ERROR_TO_ACTION;

  const [shownError, setShownError] = useState<ErrorToAction | null>(null);
  const [dismissedTimedErrors, setDismissedTimedErrors] = useState<TimedError[]>([]);

  // generate the errorToAction maker with the proper params
  const makeErrorToAction = useCallback((timedError: TimedError) => {
    const dismiss = () => {
      setShownError(null);
      setDismissedTimedErrors((prevState) => [...prevState, timedError]);
    };
    return errorActionMaker({ dismiss, timedError, source: errorSource });
  }, [setShownError, setDismissedTimedErrors, errorSource]);

  // passLocalShownErrorToSES
  useEffect(() => {
    if (shownError && sameNotUndefinedTimedErrors(shownError.timedError, mySourcedError)) {
      show(shownError);
      return;
    }
    // there is a shown error from source (but we are not showing)
    if (shownError === null) {
      const dismissedTimedError = dismissedTimedErrors[dismissedTimedErrors.length - 1];
      if (typeof dismissedTimedError === "undefined") return;
      const idx = shownErrorStack.findIndex((item) => item.timedError === dismissedTimedError);
      if (idx !== -1) {
        addOrRemoveSourcedError(errorSource, dismissedTimedError, AddRemove.remove);
        return;
      }
    }

    // todo remove from dismissed once error context shown has been updated
  }, [shownError, dismissedTimedErrors]);

  // copyContextShownErrorToLocalState
  useEffect(() => {
    // if there is no sourced error we dont need to copy it
    if (!mySourcedError) {
      return;
    }
    // if it differs from what is currently shown (or nothing is shown)
    // we copy it to local state as a ErrorToAction
    // if a shownError was already added, we skip it (we could replace it though)
    if (mySourcedError !== topSESTimedError && !shownError) {
      const se = makeErrorToAction(mySourcedError);
      setShownError(se);
    }
  }, [mySourcedError, topSESSource, topSESTimedError, makeErrorToAction]);
};

export default useErrorManager;
