import React from "react";
import { t } from "i18next";
import "./index.scss";

type ComponentProps = {
  triggerSearch: () => void;
}

const SearchButton: React.FC<ComponentProps> = ({
  triggerSearch
}) => {
  return (
    <button
      className="SearchButton"
      onClick={triggerSearch}
      aria-label={t("Search")}
    >
      {t("Search")}
    </button>
  );
};

export default SearchButton;