import { RemoteServiceId } from "../api/implementations/types";

export type AuthenticateResponse = {
  result: {
    Authenticate: {
      addressId?: string;
      displayName: string;
      firstName: string;
      freeOne?: string;
      id?: string;
      lastName: string;
      login: string;
      mail?: string;
      mobilePhone?: string;
      origin: RemoteServiceId;
    }
  };
};

export enum NavigationKeys {
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Enter = "Enter",
  Space = " ",
  Escape = "Escape",
  Home = "Home",
  End = "End",
  PageUp = "PageUp",
  PageDown = "PageDown"
}