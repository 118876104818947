import React from "react";
import NavBar from "../../components/NavBar";
import BookResults from "../../components/BookResults";
import Footer from "../../components/Footer";
import ErrorModal from "../../components/ErrorModal";
import Page from "../../components/Page";
import { t } from "i18next";

export default function Home(): JSX.Element {
  return (
    <Page
      header={<NavBar modifier="left-on-mobile" />}
      main={<BookResults />}
      footer={<Footer />}
    >
      <ErrorModal />
    </Page>
  );
}
