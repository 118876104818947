import { Location, NavigateFunction } from "react-router-dom";
import { I18NLabelHandle, NewSearchProps, QueryType } from "../types/search";
import { Format, NavSearchProps, ValuesParam, YouthText } from "../types/search";
import { RemoteServiceId } from "../api/implementations/types";

export const forcedOrigin = import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN;

export function toIntOr(str?: string, otherwise?: number) {
  return (typeof str === "string" && /^-?\d+$/.test(str) && parseInt(str, 10))
    || (otherwise || 0);
}

export const searchByOriginGen = (navigate: NavigateFunction) => (origin: string) => {
  const searchQuery: NewSearchProps = {
    queryText: "",
    page: 0,
    count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
    queryType: QueryType.text,
    origin,
  };
  navigate("/", { state: searchQuery });
};

export const searchByAuthorGen = (navigate: NavigateFunction) => (author: string) => {
  const searchQuery: NewSearchProps = {
    queryText: author,
    page: 0,
    count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
    queryType: QueryType.author,
  };
  navigate("/", { state: searchQuery });
};

export const searchByGen = (navigate: NavigateFunction) => (
  labelI18NHandle: I18NLabelHandle,
  value: string
) => {
  const searchQuery: NewSearchProps = {
    page: 0,
    count: toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10),
    ...applyOptionallyForcedOrigin(labelI18NHandle === I18NLabelHandle.Origin
      ? value as RemoteServiceId
      : undefined),
    // queryText: labelI18NHandle === I18NLabelHandle.Reader
    //   ? formatReader(value)
    //   : (labelI18NHandle !== I18NLabelHandle.Origin && value) || "",
    // queryType: (labelI18NHandle === I18NLabelHandle.AudioBook
    //     || labelI18NHandle === I18NLabelHandle.Origin)
    //     ? QueryType.text
    //     : labelI18NHandle.toLowerCase() as QueryType
    ...getNewSearchProps(labelI18NHandle, value),
  };

  navigate(getSearchRouteFromParams(searchQuery));
};

function formatReader(value: string): string {
  return value.replace(/Mme \.?/g, "").replace(/M \.?/g, "").split(",")[0]
}

function getNewSearchProps(
  labelI18NHandle: I18NLabelHandle,
  value: string
): {
  queryType: NewSearchProps["queryType"],
  queryText: NewSearchProps["queryText"]
  gatewayGenreCode?: NewSearchProps["gatewayGenreCode"];
} {
  switch (labelI18NHandle) {
    case I18NLabelHandle.Author:
      return {
        queryType: QueryType.author,
        queryText: value,
      };
    case I18NLabelHandle.Reader:
      return {
        queryType: QueryType.reader,
        queryText: formatReader(value),
      };
    case I18NLabelHandle.Genre:
      return {
        queryType: QueryType.text,
        queryText: '',
        gatewayGenreCode: value,
      };
    default:
      return {
        queryType: QueryType.text,
        queryText: value,
      };
  }
}

export function applyOptionallyForcedOrigin(originIfNotForced?: RemoteServiceId) {
  if (forcedOrigin) {
    return { origin: forcedOrigin };
  }
  return (originIfNotForced && { origin: originIfNotForced }) || {};
}

export const EMPTY_QUERY_TEXT_VALUE = "_";

export function valuesParamAreDifferent(vp1: ValuesParam, vp2: ValuesParam) {
  return vp1.page !== vp2.page
    || vp1.count !== vp2.count
    || vp1.queryText !== vp2.queryText
    || vp1.queryType !== vp2.queryType
    || vp1.gatewayGenreCode !== vp2.gatewayGenreCode
    || vp1.format !== vp2.format
    || vp1.youth !== vp2.youth;
}

export type SearchRouteParams = {
  by: string;
  searchedText: string;
  page: string;
  genre: string;
  format: string;
  youth: string;
};

export type RouteParams = Partial<SearchRouteParams>;

export function getSearchValuesParam(
  { by, searchedText, genre, page, format, youth }: RouteParams,
  location: Location
): ValuesParam {
  const resultsPerPage = toIntOr(import.meta.env.VITE_RESULTS_PER_PAGE, 10);
  if (location.state !== null && location.state !== undefined) {
    return location.state as ValuesParam;
  }

  const base = {
    page: toIntOr(page, 0),
    count: resultsPerPage,
  };

  if (by || searchedText || genre || format || youth) {
    return {
      ...base,
      queryType: by === EMPTY_QUERY_TEXT_VALUE ? QueryType.text : by as QueryType,
      queryText: searchedText === EMPTY_QUERY_TEXT_VALUE ? undefined : searchedText,
      gatewayGenreCode: genre === EMPTY_QUERY_TEXT_VALUE ? undefined : genre,
      format: format === EMPTY_QUERY_TEXT_VALUE ? Format.all : format as Format,
      youth: youth === EMPTY_QUERY_TEXT_VALUE ? undefined : youth as YouthText,
    };
  }

  return base;
}

export function getSearchRouteFromParams({
  queryType,
  queryText,
  gatewayGenreCode,
  page,
  format,
  youth,
}: NavSearchProps) {
  if (typeof queryText === "undefined" && typeof queryType === "undefined" && typeof gatewayGenreCode === "undefined") {
    const searchString = `/${page || 0}/${format || Format.all}/${youth || YouthText.no}`;
    return searchString;
  }
  const finalQueryType = queryType === "reader" && queryText?.length ? "reader" : "text";
  const searchString = `/search/${finalQueryType}/${queryText || EMPTY_QUERY_TEXT_VALUE}/${page || 0}/${gatewayGenreCode || EMPTY_QUERY_TEXT_VALUE}/${format || EMPTY_QUERY_TEXT_VALUE}/${youth || EMPTY_QUERY_TEXT_VALUE}`;
  return searchString;
}

const noFiltersHomeSearchRegex = new RegExp(`/\\d+/${Format.all}/${YouthText.no}`);
const noFiltersSearchRegex = new RegExp(`/search/${QueryType.text}/${EMPTY_QUERY_TEXT_VALUE}/\\d+/${EMPTY_QUERY_TEXT_VALUE}/${Format.all}/${YouthText.no}`);
export function doParamsHaveAppliedFilters(params: NavSearchProps) {
  const searchString = getSearchRouteFromParams(params);
  return !noFiltersHomeSearchRegex.test(searchString) && !noFiltersSearchRegex.test(searchString);
}
