import React from "react";
import { useRouteError } from "react-router-dom";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export default function ErrorPage(): JSX.Element {
  const { t } = useTranslation(["common"]);
  const error: any = useRouteError();

  useDocumentTitle(t("Error Page"));

  return (
    <Page id="error-page">
      <h1>{t("Oops!")}</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <Footer />
    </Page>
  );
}
